export function getAccessToken() {
	if(typeof localStorage.getItem('persist:root') !== 'undefined') {
		const persistedCurrentUserString = localStorage.getItem('persist:root');
		const persistedCurrentUser = JSON.parse(persistedCurrentUserString);
		if(!persistedCurrentUser) {
			return null;
		}
		const persistedUserObject = JSON.parse(persistedCurrentUser.currentUser);
		return persistedUserObject.accessToken;
	}
	return null;
}

export function getUserId(myUser = null) {
	if(myUser && myUser.user && myUser.user.id) {
		return myUser.user.id;
	}
	if(typeof localStorage.getItem('persist:root') !== 'undefined') {
		const persistedCurrentUserString = localStorage.getItem('persist:root');
		const persistedCurrentUser = JSON.parse(persistedCurrentUserString);
		if(!persistedCurrentUser) {
			return null;
		}
		const persistedUserObject = JSON.parse(persistedCurrentUser.currentUser);
		if(persistedUserObject && persistedUserObject.user) {
			return persistedUserObject.user.id;
		}
	}
	return null;
}

export function getCurrentUser() {
  if(typeof localStorage.getItem('persist:root') !== 'undefined') {
    const persistedCurrentUserString = localStorage.getItem('persist:root');
    const persistedCurrentUser = JSON.parse(persistedCurrentUserString);
		if(!persistedCurrentUser) {
			return null;
		}
    return JSON.parse(persistedCurrentUser.currentUser);
  }
  return null;
}
