import React, {Component} from 'react';
import MediaQuery from 'react-responsive';
import Config from '../config/Config';
import {connect} from 'react-redux'
import DesktopNav from '../ui/nav/DesktopNav';
import MobileNav from '../ui/nav/MobileNav';


class InnerApp extends Component {
    render() {
        return (
            <div>
                <MediaQuery minWidth={Config.minSmScreen}>
                    <DesktopNav history={this.props.history} location={this.props.location.pathname} />
                </MediaQuery>
                <MediaQuery maxWidth={Config.maxXSmScreen}>
                    <MobileNav history={this.props.history} location={this.props.location.pathname} />
                </MediaQuery>
                <div className="app-content-wrapper adjust-height">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default InnerApp;
