import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Radium from 'radium';

class CenteredPaperLayout extends Component {

    render() {
        return (
            <div className={(this.props.className ? this.props.className + ' ' : '') + "bla-centered-paper-wrapper"}>
                <div className="bla-centered-paper" style={{backgroundColor:"black"}}>
                    <div className="paper-container-div" >
                        <Paper className="centered-paper-layout">
                            {this.props.children}
                        </Paper>
                    </div>
                </div>
            </div>
        );
    }
}
CenteredPaperLayout = Radium(CenteredPaperLayout);
export default CenteredPaperLayout;
