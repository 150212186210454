import React, {Component} from 'react';
import BlaButton from "../../ui/buttons/BlaButton";
import {getUserId} from "../../util/storage/accessTokenStorage";
import {connect} from "react-redux";
import AppRoutes from "../../config/AppRoutes";
import {createBrowserHistory} from 'history';
const browserHistory = createBrowserHistory();



class TestSelectionView extends Component {
    userHasTest = (type) => {
      return this.props.data && this.props.data[type] === "1";
    };
    
    redirectToTestTermsPage = (type) => {
      this.props.history.push(AppRoutes.TEST_TERMS_PAGE.replace(":type", type))
    };

    render() {
        return(
            <div>
                <h2 className={"bla-centered-paper-layout"}>TEST SELECTION</h2>
                <div>
                    <div className="bla-center" style={{display: 'none'}}>
                        <h3>GENERAL ORDERS - SERGEANT</h3>
                        <div className="bla-button-container">
                            <BlaButton
                              onClick={() => this.redirectToTestTermsPage("General Orders Sergeant")}
                              label="Start Test"
                              disabled={!this.userHasTest('hasGeneralOrders')}
                            />
                        </div>
                    </div>
                    <div className="bla-center">
                        <h3>GENERAL ORDERS</h3>
                        <div className="bla-button-container">
                            <BlaButton
                              onClick={() => this.redirectToTestTermsPage("General Orders")}
                              label="Start Test"
                              disabled={!this.userHasTest('hasGeneralOrders')}
                            />
                        </div>
                    </div>

                    <div className="bla-center">
                        <h3>EMERGENCY RESPONSE PLAN</h3>
                        <div className="bla-button-container">
                            <BlaButton
                              onClick={() => this.redirectToTestTermsPage("Emergency Response Plan")}
                              label="Start Test"
                              disabled={!this.userHasTest('hasEmergencyResponsePlan')}
                            />
                        </div>
                    </div>


                    <div className="bla-center" style={{display: 'none'}}>
                        <h3>CORRECTIVE ACTION MANUAL</h3>
                        <div className="bla-button-container">
                            <BlaButton
                              onClick={() => this.redirectToTestTermsPage("Corrective Action Manual")}
                              label="Start Test"
                              disabled={!this.userHasTest('hasCorrectiveActions')}
                            />
                        </div>
                    </div>

                    <div className="bla-center" style={{display: 'none'}}>
                        <h3>GENERAL ORDERS - COMMANDER</h3>
                        <div className="bla-button-container">
                            <BlaButton
                              onClick={() => this.redirectToTestTermsPage("General Orders Captain")}
                              label="Start Test"
                              disabled={!this.userHasTest('hasAdditionalTestOne')}
                            />
                        </div>
                    </div>
    
                    <div className="bla-center">
                        <h3>SERGEANT 2024</h3>
                        <BlaButton
                          onClick={() => this.redirectToTestTermsPage("Sergeant 2024")}
                          label="Start Test"
                          disabled={!this.userHasTest('hasAdditionalTestTwo')}
                        />
                    </div>
    
                    <div className="bla-center" style={{display: 'none'}}>
                        <h3>2020 SERGEANT COMBINED TEST</h3>
                        <BlaButton
                          onClick={() => this.redirectToTestTermsPage("Sergeant Combined 2020")}
                          label="Start Test"
                          disabled={!this.userHasTest('hasTwentyOneIrrefutableLaws')}
                        />
                    </div>

                    <div className="bla-center">
                        <h3>MY TESTS</h3>
                        <div className="bla-button-container">
                            <BlaButton
                              onClick={() => this.props.history.push(AppRoutes.TEST_LIST_PAGE.replace(':id', getUserId()))}
                              label="View Tests"
                            />
                        </div>
                    </div><br />
                </div>
          </div>
        )
    }
}

export default connect()(TestSelectionView);
