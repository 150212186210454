export const ACCESS_TOKEN_ACQUIRED = 'ACCESS_TOKEN_ACQUIRED';
export function accessTokenAcquired() {
    return {
        type: ACCESS_TOKEN_ACQUIRED
    }
}

export const LOG_OUT_USER = 'LOG_OUT_USER';
export function logOutUser(history) {
    history ? history.push('/') : window.location = '/';
    return {
        type: LOG_OUT_USER
    }
}
