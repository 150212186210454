/*import {fade} from "material-ui/utils/colorManipulator";
import {
    grey500, grey700, blueGrey400, cyanA100, cyanA200, white, darkBlack, grey300, fullBlack, grey50
} from "material-ui/styles/colors";
import getMuiTheme from 'material-ui/styles/getMuiTheme';

export const muiTheme = getMuiTheme({
    palette: {
        primary1Color: white,
        primary2Color: grey700,
        primary3Color: blueGrey400,
        accent1Color: '#59e0f6',
        accent2Color: cyanA100,
        accent3Color: white,
        textColor: fade(white, 0.8),
        alternateTextColor: '#59e0f6',
        canvasColor: fullBlack,
        borderColor: fade(white, 0.8),
        disabledColor: white,
        pickerHeaderColor: blueGrey400,
        clockCircleColor: fade(darkBlack, 0.07),
        shadowColor: blueGrey400,
    },
});

 */

import { createMuiTheme } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import blueGrey from '@material-ui/core/colors/blueGrey';
import cyan from '@material-ui/core/colors/cyan';

export const muiTheme = createMuiTheme({
    palette: {
        primary1Color: 'white',
        primary2Color: grey[700],
        primary3Color: blueGrey[400],
        accent1Color: '#59e0f6',
        accent2Color: cyan['A100'],
        accent3Color: 'white',
        textColor: 'rgba(255,255,255,0.8)', //fade(white, 0.8),
        alternateTextColor: '#59e0f6',
        canvasColor: 'black',
        borderColor: 'rgba(255,255,255,0.8)',
        disabledColor: 'white',
        pickerHeaderColor: blueGrey[400],
        clockCircleColor: 'rgba(0,0,0,0.8)', // fade(darkBlack, 0.07),
        shadowColor: blueGrey[400],
    }
});
