import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import BaseNonAdminListContainer from './base/BaseNonAdminListContainer';
import mapFetchToProps from '../util/mapFetchToProps';

let NonAdminListContainer = connect(mapFetchToProps)(BaseNonAdminListContainer);

NonAdminListContainer.propTypes = { //for IDE. Delete if you want
  id: PropTypes.string.isRequired,
  accessToken: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  itemComponent: PropTypes.func.isRequired,
  noData: PropTypes.func.isRequired,
  sortControls: PropTypes.bool.isRequired,
  search: PropTypes.bool.isRequired,
  createControl: PropTypes.bool.isRequired,
  createUrl: PropTypes.string,
  getAvatar: PropTypes.func,
  getLabel: PropTypes.func,
  getIconButton: PropTypes.func
};

export default NonAdminListContainer;
