import React, {Component} from 'react';
import PropTypes from 'prop-types';
import VerticallyCenter from '../../layouts/centerers/VerticallyCenter';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from 'react-router-dom';


class NavItem extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let className = this.props.to.replace(":id", "") == this.props.location.replace(":id", "") ? "selected-nav-item" : "";
        let name = this.props.name ? this.props.name : "";
        return (
            <Link to={this.props.to} className={this.props.className} onClick={() => this.props.onClick(this.props.to)}>
                <MenuItem className={className} >
                    <VerticallyCenter height="60px">
                        <div style={{height: 36, lineHeight: "36px"}}>
                            {this.props.icon}
                            <div className="nav-label" style={{
                                display: 'inline-block',
                                position: 'relative',
                                left: 16,
                                height: 18,
                                bottom: 10,
                                lineHeight: '18px'}}>
                                {name}
                            </div>
                        </div>
                    </VerticallyCenter>
                </MenuItem>
            </Link>
        )
    }
}

NavItem.propTypes = {
    to: PropTypes.string,
    location: PropTypes.string,
    onClick: PropTypes.func
};

export default NavItem;
