import React, {Component} from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import Formsy from 'formsy-react';
import BlaButton from "../../ui/buttons/BlaButton";
import CenteredPaperLayout from "../../layouts/CenteredPaperLayout";
import {getAccessToken} from "../../util/storage/accessTokenStorage";
import ApiConfig from "../../config/ApiConfig";
import {wait, unWait} from "../../actions/uiActions";
import {doSchemaCreate} from "../../actions/thunks/schemaThunks";
import {connect} from "react-redux";
import AppRoutes from "../../config/AppRoutes";
import {createBrowserHistory} from 'history';
import { FormGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { addError, unknownError } from '../../actions/errorActions';
import { doLogin } from '../../actions/thunks/loginThunks';
import fetch from 'isomorphic-fetch';
import standardResponseHandler from '../../actions/thunks/standardResponseHandler';
import doNothing from '../../actions/thunks/successHandlers/doNothing';
const browserHistory = createBrowserHistory();


class TestTermsPage extends Component {
  
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      isAcceptTermsBoxChecked: false,
      isAuthorizedUserBoxChecked: false
    };
  }
  
  acceptTerms = () => {
    this.setState({
      isAcceptTermsBoxChecked: !this.state.isAcceptTermsBoxChecked
    });
  };
  
  authorizedUser = () => {
    this.setState({
      isAuthorizedUserBoxChecked: !this.state.isAuthorizedUserBoxChecked
    });
  };
  
  createTestOfType = (type) => {
    this.props.dispatch(wait());
    const errorMessage = 'Unable to determine geolocation. You must provide geolocation to use this service.';
  
    if(!navigator || !navigator.geolocation) {
      this.props.dispatch(addError('Your browser does not support geolocation. Please use a browser that supports geolocation'));
      return;
    }
  
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        const accuracy = position.coords.accuracy;
        const geolocationType = 'Test Begin';
        const payload = { lat, lng, accuracy, type: geolocationType };
        
        const endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.geolocation;
  
        const bodyJson = JSON.stringify(payload);
        fetch(endpoint, {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Authorization' : 'Bearer ' + getAccessToken(),
            'Accept' : 'application/json',
            'Content-Type' : 'application/json'
          },
          body: bodyJson
        })
        .then(async response => {
          const json = await response.json();
          if(response.ok && json.success) {
            const url = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.testEndpoint;
            this.props.dispatch(doSchemaCreate(getAccessToken(), url, true, { type }, this.redirectToPageCallback));
            return;
          }
          this.props.dispatch(addError(errorMessage))
        })
        .catch(error => {
          this.props.dispatch(addError(errorMessage))
        })
      },
      (error) => {
        this.props.dispatch(addError(errorMessage));
      });
  };
  
  redirectToPageCallback = (json) => {
    const id = json.id;
    this.props.dispatch(unWait());
    this.props.history.push(AppRoutes.TEST_PAGE.replace(":id", id).replace(":index", "0"));
  };
  
  render() {
    return <CenteredPaperLayout>
      <h2 className={"bla-centered-paper-layout"}>TEST CENTER</h2>
      <span className="test-terms-link-header">
        <a
          className={"bla-hype-style"}
          onClick={(event) => event.stopPropagation()}
          href="https://www.bluelineadvances.com/terms-and-conditions.html"
          target="_blank">
          Terms and Conditions
        </a>
      </span>
      <Formsy>
        <FormGroup>
          <div className="test-terms-checkbox-wrapper">
            <div className="test-terms-checkbox">
              <FormControlLabel
                className="bla-terms-label"
                control={
                  <Checkbox
                    className="test-terms-authorization-checkbox"
                    onChange={this.acceptTerms}
                  />
                }
                label="I accept the above Terms and Conditions."
              />
              
            </div>
          </div>
          <div className="test-terms-checkbox-wrapper with-label">
            <div className="test-terms-checkbox-with-label">
              <FormControlLabel
                className="bla-terms-label"
                control={
                  <Checkbox
                    className="test-terms-authorization-checkbox-with-label"
                    onChange={this.authorizedUser}
                  />
                }
                label="I attest that I am the authorized user of this account."
              />
            </div>
          </div>
        </FormGroup>
      </Formsy>
      <div style={{marginTop: 12}}>
        <BlaButton
          onClick={() => this.createTestOfType(this.props.match.params.type)}
          label="Start Test"
          disabled={!(this.state.isAcceptTermsBoxChecked && this.state.isAuthorizedUserBoxChecked)}
        />
      </div>
    </CenteredPaperLayout>;
  }
  
}

export default connect()(TestTermsPage)
