import React, { PureComponent } from 'react';
import ApiConfig from '../../config/ApiConfig';
import { getCurrentUser } from '../../util/storage/accessTokenStorage';
import AdminNavigation from '../partials/navigation/AdminNavigation';
import BigLayout from '../../layouts/BigLayout';
import { logOutUser } from '../../actions/globalActions';
import NoData from '../partials/empty_data/NoData';
import GeolocationUsersListItem from '../partials/list_items/GeolocationUsersListItem';
import BaseGeolocationUserListContainer from '../../containers/base/BaseGeolocationUserListContainer';

class GeolocationUsers extends PureComponent {
  validateAdminUser = (user) => {
    if(user.user.isAdmin !== '1') {
      this.props.dispatch(logOutUser(this.props.history));
    }
  };
  
  render() {
    const user = getCurrentUser();
    this.validateAdminUser(user);
    
    const endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.userList;
    
    return (
      <div>
        <AdminNavigation history={this.props.history} page="Geolocation Users" />
        <BigLayout>
          <BaseGeolocationUserListContainer
            id="geolocationUsers"
            endpoint={endpoint}
            search={true}
            noData={NoData}
            itemComponent={GeolocationUsersListItem}
          />
        </BigLayout>
      </div>
    );
  }
}

export default GeolocationUsers;
