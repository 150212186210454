import React, {Component} from 'react';
import CenteredPaperLayout from '../../layouts/CenteredPaperLayout';
import DataContainer from '../../containers/DataContainer';
import TestWrapUpView from '../views/TestWrapUpView';
import ApiConfig from '../../config/ApiConfig';


class TestWrapUpPage extends Component {
    render() {
      console.log(this.props);
        let endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.testEndpoint + 'wrapup/' + this.props.match.params.id;

        return (
            <CenteredPaperLayout>
                <DataContainer
                    id="testWrapUpPage"
                    endpoint={endpoint}
                >
                    <TestWrapUpView history={this.props.history} testId={this.props.match.params.id} />
                </DataContainer>
            </CenteredPaperLayout>
        )
    }
}

export default TestWrapUpPage;
