import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';

class MobileNavItem extends Component {

	render() {
		let name = this.props.name;

		return(<Link onClick={(event) => this.props.onClick(this.props.to, event)} >
			<MenuItem >
				<div>
					{name}
				</div>
			</MenuItem>
		</Link>);
	}
}

MobileNavItem.propTypes = {
	name: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired
};

export default MobileNavItem;
