import fetch from 'isomorphic-fetch';
import ApiConfig from '../../config/ApiConfig';
import {loginResponse} from '../loginActions';
import {addError, loginError, schemaError} from '../errorActions';
import {accessTokenAcquired} from '../globalActions';
import {wait} from '../uiActions';
import AppRoutes from '../../config/AppRoutes';
import redirect from '../redirect/redirect';
import standardResponseHandler from './standardResponseHandler';

export function doLogin(user, history, geolocation = null) {
    return function(dispatch) {
        let {protocol, apiUri, loginEndpoint} = ApiConfig;
        dispatch(wait());

        const url = protocol + apiUri + loginEndpoint;
        const body = {user, geolocation, grant_type: 'password'};
        const bodyJson = JSON.stringify(body);

        return fetch(url, {
            body: bodyJson,
            method: 'POST'
        })
            .then(response => standardResponseHandler(
              response,
              dispatch,
              errorHandler,
              (json, dispatch) => handleSuccessfulRegistrationResponse(json, dispatch, history))
            )
            .catch(error => dispatch(schemaError(error)))
    }
}

function errorHandler(error, dispatch) {
    dispatch(addError("Invalid username or password"))
}

function handleSuccessfulRegistrationResponse(json, dispatch, history) {
    if(!json.error) {
        dispatch(loginResponse(json));
        dispatch(accessTokenAcquired());
		let defaultRoute = AppRoutes.TEST_SELECTION_PAGE;
		if(json.user && json.user.authorizationId) {
			defaultRoute = AppRoutes.TEST_SELECTION_PAGE.replace(":id",json.user.authorizationId);
		}
    history.push(defaultRoute);
    }
    else { //Needed for OAuth Server's error handling :(
        if(json.error_description) {
            let error = {message: json.error_description};
            dispatch(schemaError(error));
        }
        else {
            dispatch(schemaError(json));
        }
    }
}
