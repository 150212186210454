import React, {Component} from 'react';
import BlaButton from '../../../ui/buttons/BlaButton';
import AppRoutes from '../../../config/AppRoutes';

class AdminNavigation extends Component {
  navigateToHome = () => {
    this.props.history.push(AppRoutes.ADMIN_PAGE);
  };

  navigateToGeneralOrders = () => {
    this.props.history.push(AppRoutes.ADMIN_QUESTIONS_PAGE.replace(':type', 'GO'));
  };

  navigateToGeneralOrdersCaptain = () => {
    this.props.history.push(AppRoutes.ADMIN_QUESTIONS_PAGE.replace(':type', 'GOC'));
  };

  navigateToGeneralOrdersSergeant = () => {
    this.props.history.push(AppRoutes.ADMIN_QUESTIONS_PAGE.replace(':type', 'GOS'));
  };

  navigateToEmergencyResponsePlan = () => {
    this.props.history.push(AppRoutes.ADMIN_QUESTIONS_PAGE.replace(':type', 'ERP'));
  };

  navigateToCorrectiveActionsManual = () => {
    this.props.history.push(AppRoutes.ADMIN_QUESTIONS_PAGE.replace(':type', 'CAM'));
  };
  
  navigateToGeolocationUsers = () => {
    this.props.history.push(AppRoutes.GEOLOCATION_USERS);
  };

  navigateToTestList = () => {
    this.props.history.push(AppRoutes.ADMIN_TEST_LIST_PAGE);
  };
  
  navigateToCreateCommanderTest = () => {
    this.props.history.push(AppRoutes.TEST_TERMS_PAGE.replace(":type", 'Commander'));
  };

  render() {
    return (
      <div className="admin-nav-wrapper">
        <BlaButton
          disabled={false}
          emphasized={this.props.page === 'Home'}
          onClick={this.navigateToHome}
          label="Home"
        />
        <BlaButton
          disabled={false}
          emphasized={this.props.page === 'General Orders Sergeant'}
          onClick={this.navigateToGeneralOrdersSergeant}
          label="G.O."
        />
        {/*<BlaButton*/}
        {/*  disabled={false}*/}
        {/*  emphasized={this.props.page === 'General Orders'}*/}
        {/*  onClick={this.navigateToGeneralOrders}*/}
        {/*  label="G.O. LT"*/}
        {/*/>*/}
        {/*<BlaButton*/}
        {/*  disabled={false}*/}
        {/*  emphasized={this.props.page === 'General Orders Captain'}*/}
        {/*  onClick={this.navigateToGeneralOrdersCaptain}*/}
        {/*  label="G.O. CMDR"*/}
        {/*/>*/}
        
        <BlaButton
          disabled={false}
          emphasized={this.props.page === 'Emergency Response Plan'}
          onClick={this.navigateToEmergencyResponsePlan}
          label="Emergency Response Plan"
        />
        <BlaButton
          disabled={false}
          emphasized={this.props.page === 'Corrective Actions Manual'}
          onClick={this.navigateToCorrectiveActionsManual}
          label="Corrective Actions Manual"
        />
        <BlaButton
          disabled={false}
          emphasized={this.props.page === 'Geolocation Users' || this.props.page === 'Geolocation User Detail'}
          onClick={this.navigateToGeolocationUsers}
          label="Geolocation Users"
        />
        <BlaButton
          disabled={false}
          emphasized={this.props.page === 'Test List'}
          onClick={this.navigateToTestList}
          label="Test List"
        />
      </div>
    );
  }
}

AdminNavigation.propTypes = {};

export default AdminNavigation;
