export default {
    APP_BASE: '/app',
    TEST_PAGE: '/app/test/:id/question/:index',
    TEST_REVIEW_PAGE: '/app/testReview/:id/question/:index',
    TEST_SELECTION_PAGE: '/app/testSelection/:id',
    TEST_WRAP_UP_PAGE: '/app/testWrapUp/:id',
    TEST_LIST_PAGE: '/app/testList/:id',
    ADMIN_PAGE: '/app/admin',
    ADMIN_TEST_LIST_PAGE: '/app/adminTestList',
    ADMIN_QUESTIONS_PAGE: '/app/adminQuestions/:type',
    DEFAULT_ROUTE: '/',
    TEST_TERMS_PAGE: '/app/testTermsPage/:type',
    RESET_PASSWORD_REQUEST_PAGE: '/resetPassword',
    RESET_PASSWORD_PAGE: '/reset/:hash/:email',
    GENERAL_ORDERS_STATS_PAGE: '/app/generalOrdersStats',
    GEOLOCATION_USERS: '/app/geolocationUsers',
    GEOLOCATION_USER_DETAIL: '/app/geolocationUser/:id'
}
