import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RefreshIndicator from "@material-ui/core/CircularProgress";

class LoadAwareFormLayout extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    wrapperClassName: PropTypes.string,
    childrenWithProps: PropTypes.any.isRequired,
    loadingState: PropTypes.any.isRequired,
    loading: PropTypes.bool.isRequired
  };

  render() {
    return (
      <div id={this.props.id} className={this.props.wrapperClassName + ' spinner-wrapper'}>
        {this.props.childrenWithProps}
        {this.props.loading &&
          <RefreshIndicator
            status={this.props.loadingState}
            hidden={!this.props.loading}
            size={40}
            left={-20}
            top={0}
            className="local-spinner"
          />
        }
      </div>
    )
  }
}

export default LoadAwareFormLayout;
