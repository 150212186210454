import React, {Component} from 'react';
import CenteredPaperLayout from '../../layouts/CenteredPaperLayout';
import EditDataContainer from '../../containers/EditDataContainer';
import TestView from '../../pages/views/TestView';
import AppRoutes from '../../config/AppRoutes';
import ApiConfig from '../../config/ApiConfig';
import {getUserId} from "../../util/storage/accessTokenStorage";
import BigLayout from "../../layouts/BigLayout";


class TestPage extends Component {
    render() {
        let endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.testEndpoint + this.props.match.params.id;
        let requestEndpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.testEndpoint;

        return (
            <EditDataContainer
                id="testPage"
                endpoint={endpoint}
                requestEndpoint={requestEndpoint}
                redirectUrl={AppRoutes.TEST_WRAP_UP_PAGE.replace(":id", getUserId(this.props.match.params.id))}
            >
                <TestView history={this.props.history} questionIndex={this.props.match.params.index} reviewMode={false} />
            </EditDataContainer>
        )
    }
}

export default TestPage;
