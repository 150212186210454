import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getAccessToken, getUserId} from '../../util/storage/accessTokenStorage';
import fetchData from "../../util/forms/editFetch";
import LoadAwareFormLayout from "../../util/forms/components/LoadAwareFormLayout";
import onFormEdit from "../../util/forms/onFormEdit";
import redirectUpdate from "../../util/forms/redirectUpdate";
import onEditDelete from "../../util/forms/onEditDelete";
import dispatchSchemaRequest from "../../util/forms/dispatchSchemaRequest";
import dispatchFileUpload from "../../util/forms/dispatchFileUpload";
import dispatchSchemaCreate from "../../util/forms/dispatchSchemaCreate";

class BaseEditContainer extends Component {

  componentWillMount() {
    fetchData(this, false);
  }

  isCreate = () => {
    return false;
  };

  render() {
    const childrenWithProps = React.Children.map(this.props.children,
      (child) => React.cloneElement(child, {
        data: this.props.data,
        autoSuggest: this.props.autoSuggest,
        myUser: this.props.myUser,
        emptyMessage: this.props.emptyMessage || "Create",
        id: this.props.id,
        onFormEdit: (property, newValue) => onFormEdit(property, newValue, this),
        onSubmit: (data = null, message = null, requestEndpoint = null, redirectUrl = null) =>
          redirectUpdate(this, data, message, requestEndpoint, redirectUrl),
        fileUpload: (file, property) => dispatchFileUpload(this, file, property),
        avatarUpdateLabel: this.props.avatarUpdateLabel || "Add Picture",
        isCreate: this.isCreate(),
        userId: getUserId(),
        isLoading: this.props.loading,
        fetchData,
        schemaRequest: () => dispatchSchemaRequest(this),
        create: (endpoint, payload, callback) => dispatchSchemaCreate(this, endpoint, payload, callback),
        onDelete: (endpoint = null, redirectUrl = null) => onEditDelete(this, endpoint, redirectUrl),
        redirectUpdate
      })
    );

    const loadingState = this.props.loading ? "loading" : "hide";

    return <LoadAwareFormLayout
			id={this.props.id}
			wrapperClassName={this.props.className}
			childrenWithProps={childrenWithProps}
			loadingState={loadingState}
			loading={this.props.loading}
		/>;
  }
}

BaseEditContainer.propTypes = {
  id: PropTypes.string.isRequired,
  uploadEndpoint: PropTypes.string,
  endpoint: PropTypes.string,
  requestEndpoint: PropTypes.string,
  avatarUpdateLabel: PropTypes.string,
  emptyMessage: PropTypes.string,
  accessToken: PropTypes.string,
  successMessage: PropTypes.string,
  deleteRedirectUrl: PropTypes.string,
  initialData: PropTypes.any,
  autoSuggestEndpoints: PropTypes.object
};

export default BaseEditContainer;
