import {getAccessToken} from "../storage/accessTokenStorage";
import {schemaRequest} from "../../actions/schemaActions";
import {doSchemaGet} from "../../actions/thunks/schemaThunks";

const dispatchFetch = (component) => {
  let accessToken = null;
  if (!component.props.accessToken) {
    accessToken = getAccessToken();
  }
  else {
    accessToken = component.props.accessToken;
  }
  component.props.dispatch(schemaRequest(component.props.id));
  component.props.dispatch(doSchemaGet(component.props.id, accessToken, component.props.endpoint));
};

export default dispatchFetch;