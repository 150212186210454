import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Radium from 'radium';

class BigLayout extends Component {
  render() {
    return (
      <Grid componentClass="admin-wrapper">
        <Row className="show-grid" style={{backgroundColor:"black", padding:"12px"}}>
          <Col md={2} smHidden xsHidden>
          </Col>
          <Col md={12} sm={12} xs={20} >
            <div className="paper-container-div" >
              <Paper className="centered-paper-layout">
                {this.props.children}
              </Paper>
            </div>
          </Col>
          <Col md={2} smHidden xsHidden>
          </Col>
        </Row>
      </Grid>
    );
  }
}

BigLayout = Radium(BigLayout);
export default BigLayout;
