import React, { Component } from 'react';
import CenteredPaperLayout from '../../layouts/CenteredPaperLayout';
import BlaButton from '../../ui/buttons/BlaButton';
import FormsyText from '../../components/formsy/FormsyText';
import Formsy from 'formsy-react';
import { connect } from 'react-redux';
import {doPasswordResetRequest} from '../../actions/thunks/schemaThunks';
import ApiConfig from '../../config/ApiConfig';
import {addMessage} from '../../actions/uiActions';
import AppRoutes from '../../config/AppRoutes';
import {createBrowserHistory} from 'history';
const browserHistory = createBrowserHistory();

class ResetRequestPasswordPage extends Component {

  state = {
    valid: false,
    email: ''
  };

  handleValid = () => {
    this.setState({ valid: true });
  };

  handleInvalid = () => {
    this.setState({ valid: false });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  makeResetRequest = () => {
    let payload = { email: this.state.email };
    let endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.passwordResetRequestEndpoint;
    this.props.dispatch(doPasswordResetRequest(endpoint, payload, this.addSuccessMessage))
  };

  addSuccessMessage = () => {
    browserHistory.push(AppRoutes.DEFAULT_ROUTE);
    this.props.dispatch(addMessage('Please check your email to complete this process'));
  };

  emailMessage = "Real emails only";

  render() {
    return (
      <CenteredPaperLayout>
        <h2 className={"bla-centered-paper-layout"}>RESET PASSWORD</h2>
        <div className="reset-password-main-wrapper" >
          <Formsy
            onValid={this.handleValid}
            onInvalid={this.handleInvalid}
          >
            <div className="password-reset-request">
              <FormsyText
                name="email"
                validations="isEmail"
                validationError={this.emailMessage}
                required
                label="Email"
                updateImmediately
                onChange={this.handleEmailChange}
                value={this.state.email}
              />
              <BlaButton onClick={this.makeResetRequest} disabled={!this.state.valid} label="Reset Password" />
            </div>
          </Formsy>
        </div>
      </CenteredPaperLayout>
    );
  }
}

ResetRequestPasswordPage.propTypes = {};

export default connect()(ResetRequestPasswordPage);
