import React, {Component, PropTypes} from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Radium, {StyleRoot} from 'radium';
import CastleRouter from './routes/CastleRouter';
import CastleError from './ui/CastleError';
import CastleLoader from './ui/CastleLoader';
import CastleMessage from './ui/CastleMessage';
import {muiTheme} from './ui/theme/materialUiTheme';

class Castle extends Component {

    render() {
        return (
            <StyleRoot>
                <ThemeProvider theme={muiTheme}>
                    <div>
                        <CastleRouter />
                        <CastleLoader />
                        <CastleError />
                        <CastleMessage />
                    </div>
                </ThemeProvider>
            </StyleRoot>
        );
    }
}


export default Radium(Castle);
