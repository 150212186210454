export default (state, ownProps) => {
	var data = {};
	if(typeof state.autoLoaders.loaders[ownProps['id']] !== 'undefined') {
		data = state.autoLoaders.loaders[ownProps['id']];
	}
	var loadingList = state.autoLoaders.loadingList;
	var loading = typeof loadingList[ownProps['id']] !== 'undefined' && loadingList[ownProps['id']] === true;

	var autoSuggest = [];

	if(typeof state.autoLoaders.suggesters !== 'undefined') {
		autoSuggest = state.autoLoaders.suggesters[ownProps['id']];
	}

	return {
		accessToken: state.currentUser.accessToken,
		myUser: state.currentUser.user,
		data,
		loading,
		autoSuggest
	}
};