import {UNKNOWN_ERROR_MESSAGE} from '../../messaging/messages';
import redirect from '../redirect/redirect';
import {addMessage, unWait} from '../uiActions';
import {logOutUser} from '../globalActions';
import {replaceCurrentUser} from "../schemaActions";

export default function standardResponseHandler(response, dispatch, errorHandler, successHandler,
                                                successMessage = null, successRedirectUrl = null, id = null, castToJson = true, property = null, cacheUser = false, history = null) {
	dispatch(unWait());
	if(response.ok){
		handleOkResponse(response, dispatch, errorHandler, successHandler, successMessage, successRedirectUrl, id, castToJson, property, cacheUser);
	}
	else {
		handleErrorResponse(response, dispatch, errorHandler, id, history);
	}
}

function handleErrorResponse(response, dispatch, errorHandler, id, history) {
	if(response.status === 401) {
		history ? dispatch(logOutUser(history)) : console.log('need history');
		handleNonForbiddenErrorResponse(response, dispatch, errorHandler, id);
	}
	else {
		handleNonForbiddenErrorResponse(response, dispatch, errorHandler, id);
	}
}

function handleNonForbiddenErrorResponse(response, dispatch, errorHandler, id) {
	response.json()
	.then(json => errorHandler(json, dispatch, id))
	.catch(error => handleJsonCastingError(error, dispatch, errorHandler, id));
}

function handleOkResponse(response, dispatch, errorHandler, successHandler, successMessage, successRedirectUrl, id, castToJson, property, cacheUser) {
	if(castToJson) {
		response.json()
		.then(json => {
			handleSuccessScenarios(json, dispatch, successHandler, successMessage, successRedirectUrl, id, property, cacheUser)
		})
		.catch(error => handleJsonCastingError(error, dispatch, errorHandler));
	}
	else {
		handleSuccessScenarios(null, dispatch, successHandler, successMessage, successRedirectUrl, id, property);
	}
}

function handleJsonCastingError(error, dispatch, errorHandler, id) {
	console.error(error);
	errorHandler(UNKNOWN_ERROR_MESSAGE, dispatch, id);
}

function handleSuccessScenarios(json, dispatch, successHandler, successMessage, successRedirectUrl, id, property, cacheUser = false) {
	successHandler(json, dispatch, id, property);
	if(successRedirectUrl) {
		redirect(successRedirectUrl);
	}
	if(successMessage) {
		dispatch(addMessage(successMessage));
	}
	if(cacheUser) {
		dispatch(replaceCurrentUser(json));
	}
}
