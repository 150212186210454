import React, {Component, PropTypes} from 'react';
import {BrowserRouter, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Register from '../pages/Register';
import InnerApp from '../layouts/InnerApp';
import AppRoutes from '../config/AppRoutes';
import appSchemeMap from '../util/appSchemeMap';

class CastleRouter extends Component {
    render() {
        const PasswordResetRequestComponent = appSchemeMap.RESET_PASSWORD_REQUEST_PAGE.component;
        const ResetPasswordPage = appSchemeMap.RESET_PASSWORD_PAGE.component;
        
        return (
            <BrowserRouter>
                <Route exact path="/" component={Home} />
                <Route
                  exact
                  path="/register"
                  component={Register}
                />
                <Route
                  exact
                  path={AppRoutes.RESET_PASSWORD_REQUEST_PAGE}
                  component={PasswordResetRequestComponent}
                />
                <Route
                  exact
                  path={AppRoutes.RESET_PASSWORD_PAGE}
                  component={ResetPasswordPage}
                />

                <Route path="/app">
                  {
                    (props) => {
                      if(props.location.pathname.substring(0,4) === '/app') {
                        return (
                          <InnerApp {...props}>
                            <Route exact path={AppRoutes.TEST_PAGE} component={appSchemeMap.TEST_PAGE.component}/>
                            <Route exact path={AppRoutes.TEST_REVIEW_PAGE}
                                   component={appSchemeMap.TEST_REVIEW_PAGE.component}/>
                            <Route exact path={AppRoutes.TEST_SELECTION_PAGE}
                                   component={appSchemeMap.TEST_SELECTION_PAGE.component}/>
                            <Route exact path={AppRoutes.TEST_WRAP_UP_PAGE}
                                   component={appSchemeMap.TEST_WRAP_UP_PAGE.component}/>
                            <Route exact path={AppRoutes.TEST_LIST_PAGE}
                                   component={appSchemeMap.TEST_LIST_PAGE.component}/>
                            <Route exact path={AppRoutes.ADMIN_PAGE} component={appSchemeMap.ADMIN_PAGE.component}/>
                            <Route exact path={AppRoutes.ADMIN_TEST_LIST_PAGE}
                                   component={appSchemeMap.ADMIN_TEST_LIST_PAGE.component}/>
                            <Route exact path={AppRoutes.ADMIN_QUESTIONS_PAGE}
                                   component={appSchemeMap.ADMIN_QUESTIONS_PAGE.component}/>
                            <Route exact path={AppRoutes.TEST_TERMS_PAGE}
                                   component={appSchemeMap.TEST_TERMS_PAGE.component}/>
                            <Route exact path={AppRoutes.GENERAL_ORDERS_STATS_PAGE}
                                   component={appSchemeMap.GENERAL_ORDERS_STATS_PAGE.component}/>
                            <Route exact path={AppRoutes.GEOLOCATION_USERS}
                                   component={appSchemeMap.GEOLOCATION_USERS.component}/>
                            <Route exact path={AppRoutes.GEOLOCATION_USER_DETAIL}
                                   component={appSchemeMap.GEOLOCATION_USER_DETAIL.component}/>
                          </InnerApp>
                        );
                      }
                      
                      return null;
                    }
                  }
                
                </Route>
            </BrowserRouter>
        );
    }
}

{/*{
                    (props) => (
                      <InnerApp {...props}>
                        <Route exact path={AppRoutes.TEST_PAGE} component={appSchemeMap.TEST_PAGE.component} />
                        <Route exact path={AppRoutes.TEST_REVIEW_PAGE} component={appSchemeMap.TEST_REVIEW_PAGE.component} />
                        <Route exact path={AppRoutes.TEST_SELECTION_PAGE} component={appSchemeMap.TEST_SELECTION_PAGE.component} />
                        <Route exact path={AppRoutes.TEST_WRAP_UP_PAGE} component={appSchemeMap.TEST_WRAP_UP_PAGE.component} />
                        <Route exact path={AppRoutes.TEST_LIST_PAGE} component={appSchemeMap.TEST_LIST_PAGE.component} />
                        <Route exact path={AppRoutes.ADMIN_PAGE} component={appSchemeMap.ADMIN_PAGE.component} />
                        <Route exact path={AppRoutes.ADMIN_TEST_LIST_PAGE} component={appSchemeMap.ADMIN_TEST_LIST_PAGE.component} />
                        <Route exact path={AppRoutes.ADMIN_QUESTIONS_PAGE} component={appSchemeMap.ADMIN_QUESTIONS_PAGE.component} />
                        <Route exact path={AppRoutes.TEST_TERMS_PAGE} component={appSchemeMap.TEST_TERMS_PAGE.component} />
                      </InnerApp>
                    )
                  }*/}

export default CastleRouter;
