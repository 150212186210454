import dispatchInitialData from "./dispatchInitialData";
import isAutoSuggestPopulated from "./isAutoSuggestPopulated";
import dispatchAutoSuggest from "./dispatchAutoSuggest";
import shouldAutoDispatchFetch from "./shouldAutoDispatchFetch";
import dispatchFetch from "./dispatchFetch";

const fetchData = (component, isMounting = false) => {
  dispatchInitialData(component);

  if(shouldAutoDispatchFetch(component, isMounting)) {
    dispatchFetch(component);
  }

  if(isAutoSuggestPopulated(component)) {
    dispatchAutoSuggest(component);
  }
};

export default fetchData;