import React, {Component} from 'react';
import CenteredPaperLayout from '../layouts/CenteredPaperLayout';

import LoginInputs from './partials/home/LoginInputs';
import RegistrationOptions from './partials/home/RegistrationOptions';
import AppRoutes from '../config/AppRoutes';
import { Link } from 'react-router-dom';

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    render() {
        return (
            <CenteredPaperLayout>
              <h2 className="bla-centered-paper-layout">TEST CENTER</h2>
              <LoginInputs history={this.props.history} style={this.props.route ? this.props.route.style : null} />
              <div className="reset-password-link-wrapper">
                <Link className="bla-hype-style reset-password-link" to={AppRoutes.RESET_PASSWORD_REQUEST_PAGE}>Forgot Password</Link>
              </div>
              <RegistrationOptions />
            </CenteredPaperLayout>
        );
    }
}

export default Home;
