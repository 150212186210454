import React, {Component} from 'react';

class NoData extends Component {
	render() {
		return (
			<div>
			No Data!
			</div>
		);
	}
}

export default NoData;