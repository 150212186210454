import {getAccessToken} from "../storage/accessTokenStorage";
import {doAutoSuggestGet} from "../../actions/thunks/schemaThunks";

const dispatchAutoSuggest = (component) => {
  const accessToken = getAccessToken();
  let autoSuggestEndpoint = "";
  for(let property in component.props.autoSuggestEndpoints) {
    if(component.props.autoSuggestEndpoints.hasOwnProperty(property)) {
      autoSuggestEndpoint = component.props.autoSuggestEndpoints[property];
      component.props.dispatch(doAutoSuggestGet(component.props.id, accessToken, autoSuggestEndpoint, property))
    }
  }
};

export default dispatchAutoSuggest;