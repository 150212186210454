import React, { PureComponent } from 'react';
import { styled } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withFormsy } from 'formsy-react';

const StyledInputLabel = styled(InputLabel)({
  fontSize: 14
});

class FormsyText extends PureComponent {
  render() {
    
    const hasError = !this.props.isPristine && this.props.errorMessage;
    const inputClass = hasError ? 'bla-formsy-text-input-error bla-formsy-text-input' : 'bla-formsy-text-input';
    const rootClass = hasError ? 'bla-formsy-text-root-error' : 'bla-formsy-text-root';
    return (
      <FormControl fullWidth >
        <StyledInputLabel className="bla-formsy-text-label" shrink>{this.props.label}</StyledInputLabel>
        <Input
          classes={{
            root: rootClass,
            input: inputClass
          }}
          type={this.props.type}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        <FormHelperText className="error-text">{!this.props.isPristine && this.props.errorMessage}</FormHelperText>
      </FormControl>
    )
  }
}

//export default withFormsy(FormsyText);

export default withFormsy(FormsyText);
