import {registrationResponse} from '../registrationActions';
import {accessTokenAcquired} from '../globalActions';
import {schemaError} from '../errorActions';
import {wait} from '../uiActions';
import fetch from 'isomorphic-fetch';
import ApiConfig from '../../config/ApiConfig';
import standardResponseHandler from './standardResponseHandler';
import AppRoutes from '../../config/AppRoutes';

export function doRegistration(user, history, geolocation = null) {
    return function(dispatch) {
        let {protocol, apiUri, registrationEndpoint} = ApiConfig;
        dispatch(wait());

        const url = protocol + apiUri + registrationEndpoint;
        const body = {user, geolocation};
        const bodyJson = JSON.stringify(body);

        return fetch(url, {
            body: bodyJson,
            method: 'POST',
            mode: 'cors'
        })
            .then(response => standardResponseHandler(
              response,
              dispatch,
              errorHandler,
              (json, dispatch) => handleSuccessfulRegistrationResponse(json, dispatch, history)
            ))
            .catch(error => dispatch(schemaError(error)))
    }
}

export function doFbRegistration(payload) {
    return function(dispatch) {
        let {protocol, apiUri, facebookRegistrationEndpoint} = ApiConfig;
        let url = protocol + apiUri + facebookRegistrationEndpoint;

        let jsonPayload = JSON.stringify(payload);

        return fetch(url, {
            body: jsonPayload,
            method: 'POST',
            mode: 'cors'
        })
            .then(response => standardResponseHandler(response, dispatch, errorHandler, handleSuccessfulRegistrationResponse))
            .catch(error => dispatch(schemaError(error)))
    }
}

function errorHandler(error, dispatch) {
    dispatch(schemaError(error))
}

function handleSuccessfulRegistrationResponse(json, dispatch, history) {
    if(!json.error) {
        dispatch(registrationResponse(json));
        dispatch(accessTokenAcquired());
		let redirectUrl = AppRoutes.TEST_SELECTION_PAGE; //TODO: assumes user id, replace with custom logic
		if(json.user && json.user.authorizationId) {
			redirectUrl = redirectUrl.replace(":id", json.user.authorizationId);
		}
		history.push(redirectUrl);
    }
    else {
        if(json.error_description) {
            dispatch(schemaError({message: json.error_description}));
        }
        else {
            dispatch(schemaError({message: "An unknown error occurred"}));
        }
    }

}
