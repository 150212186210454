import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import {createStore, applyMiddleware, compose} from 'redux'
import rootReducer from '../reducers/reducers'
import initialState from '../state/initialState'
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    blacklist: ['ui'],
    storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const loggerMiddleware = createLogger();

const store = createStore(
    persistedReducer,
    initialState,
    applyMiddleware(
      thunkMiddleware
    )
/*    compose(
        applyMiddleware(
            thunkMiddleware,
            loggerMiddleware
        )//,
        //autoRehydrate()
    )*/
);

export default store;
