import React, {Component} from 'react';
import CenteredPaperLayout from '../layouts/CenteredPaperLayout';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import MediaQuery from 'react-responsive';
import Config from '../config/Config';
import HorizontalTwoStep from '../components/ui_elements/HorizontalTwoStep';
import VerticalTwoStep from '../components/ui_elements/VerticalTwoStep';
import RegistrationStep1 from './partials/registration/RegistrationStep1';
import RegistrationStep2 from './partials/registration/RegistrationStep2';
import {doRegistration} from '../actions/thunks/registrationThunks';
import {connect} from 'react-redux';
import {replaceCurrentUser} from "../actions/schemaActions";
import { addError } from '../actions/errorActions';
import {wait} from '../actions/uiActions';


class Register extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            finished: false,
            stepIndex: 0,
            user: {

            }
        };
    }

    handleNext = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            stepIndex: 1,
            finished: false
        });
    };

    handlePrev = () => {
        const {stepIndex} = this.state;
        if (stepIndex > 0) {
            this.setState({stepIndex: stepIndex - 1});
        }
    };

    setUserProperty = (property, value) => {
        var user = this.state.user;
        user[property] = value;
        this.setState({user});
    };

    handleFinalSubmission = () => {
        this.props.dispatch(wait());
        if(!navigator || !navigator.geolocation) {
            this.props.dispatch(addError('Your browser does not support geolocation. Please use a browser that supports geolocation'));
            return;
        }
    
        navigator.geolocation.getCurrentPosition(
          (position) => {
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
              const accuracy = position.coords.accuracy;
              const user = Object.assign({}, this.state.user);
              this.props.dispatch(doRegistration(user, this.props.history, { lat, lng, accuracy }));
              this.props.dispatch(replaceCurrentUser(user));
          },
          (error) => {
              this.props.dispatch(addError('Unable to determine geolocation. You must provide geolocation to use this service.'));
          });
    };

    getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <RegistrationStep1
                    handleNext={this.handleNext}
                    setUserProperty={this.setUserProperty}
                    user={this.state.user}
                />;
            case 1:
                return <RegistrationStep2
                    onFormEdit={this.setUserProperty}
                    handlePrev={this.handlePrev}
                    data={this.state.user}
                    handleFinalSubmission={this.handleFinalSubmission}
                />;
            default:
                return 'You\'re Done!';
        }
    };

    render() {
        const {stepIndex} = this.state;
        
        return (
            <CenteredPaperLayout>
                <h2 className={"bla-centered-paper-layout"}>TEST CENTER</h2>
                <MediaQuery minDeviceWidth={Config.minSmScreen}>
                    <div className="castle-stepper">
                        <HorizontalTwoStep
                           stepIndex={stepIndex}
                           firstLabel="Basics"
                           secondLabel="Terms"
                        />
                    </div>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={Config.maxXSmScreen}>
                    <div className="castle-stepper">
                        <VerticalTwoStep
                            stepIndex={stepIndex}
                            firstLabel="Basics"
                            secondLabel="Terms"
                        />
                    </div>
                </MediaQuery>
                <div className="step-content-wrapper">
                    {this.getStepContent(stepIndex)}
                </div>
                <div>
                    <h2 className="secondary-registration-header">OR</h2>
                    <div>
                        <Link to="/">
                            <Button color="white">Take Me Back To Login</Button>
                        </Link>
                    </div>
                </div>
            </CenteredPaperLayout>
        );
    }
}

const ConnectedRegister = connect()(Register);

export default ConnectedRegister;
