import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/core/Icon';
import NavItem from './NavItem';
import LogOutNavItem from './LogOutNavItem';
import appSchemeMap from '../../util/appSchemeMap';
import {getCurrentUser} from '../../util/storage/accessTokenStorage';
import IconButton from '@material-ui/core/IconButton';

class DesktopNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDrawer: false,
            location: props.location
        };
    }

    displayNavDrawer = () => {
        this.setState({
            showDrawer: true
        })
    };

    closeDrawer = (newLocation) => {
        this.setState({
            showDrawer: false,
            location: newLocation
        })
    };

    getNavItems = () => {
    	let navItems = [];
      let scheme = {};
      let counter = 0;
      const user = (getCurrentUser() && getCurrentUser().user) ? getCurrentUser().user : null;
		for(let i in appSchemeMap) {
			if(appSchemeMap.hasOwnProperty(i)) {
				scheme = appSchemeMap[i];
				if(scheme.isNav && (scheme.name !== 'Admin' || (user && user.isAdmin === "1"))) {
					navItems.push(
						<NavItem
							key={scheme.url}
							to={scheme.url} name={scheme.name}
							location={this.state.location} onClick={this.closeDrawer} />
          );

					counter++;
				}
			}
		}
		return navItems;
	};

    render() {
		let navItems = this.getNavItems();
        return (
            <div className="desktop-nav-wrapper">
                <AppBar
                    title="Blue Line Advances"
                    className="app-bar"
                >
                  <IconButton edge="start" onClick={this.displayNavDrawer} className="desktop-hamburger-icon-button" color="inherit" aria-label="menu">
                    <MenuIcon>menu</MenuIcon>
                  </IconButton>
                  <div className="bla-app-bar-title">
                    <span className="bla-app-bar-title-text">Blue Line Advances</span>
                  </div>
                </AppBar>
                <Drawer
                    className="drawer"
                    anchor="left"
                    docked={false}
                    open={this.state.showDrawer}
                    onClose={(showDrawer) => this.setState({showDrawer: false})}
                >
					          {navItems}
                    <LogOutNavItem history={this.props.history} closeDrawer={() => this.setState({showDrawer: false})} />
                </Drawer>
            </div>
        );
    }
}

export default DesktopNav;
