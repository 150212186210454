'use strict';
import React, {Component} from 'react';
import FormsyText from '../../../components/formsy/FormsyText';
import Formsy from 'formsy-react';
import BlaButton from "../../../ui/buttons/BlaButton";

class RegistrationStep1 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formValid: false
        };
    }

    handleValid = () => {
        this.setState({formValid: true});
    };

    handleInvalid = () => {
        this.setState({formValid: false});
    };

    handleUsernameChange = (event) => {
        const value = event.target.value;
        this.props.setUserProperty("email", value);
    };

    handlePasswordChange = (event) => {
        const value = event.target.value;
        this.props.setUserProperty("password", value);
    };

    render() {
        let {formValid} = this.state;
        console.log(formValid);
        let requiredError = "This field required";
        let emailError = "Real emails only please";
        return (
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Formsy
                onValid={this.handleValid}
                onInvalid={this.handleInvalid}
            >
                <div style={{ width: 256 }}>
                    <h3 className={"bla-centered-paper-layoutTwo"}>REGISTER</h3>
                    <FormsyText
                        name="email"
                        required
                        validations="isEmail"
                        validationError={emailError}
                        requiredError={requiredError}
                        label="Email"
                        onChange={this.handleUsernameChange}
                        value={this.props.user.email}
                    />
                </div>
                <div style={{ width: 256 }}>
                    <FormsyText
                        name="password"
                        required
                        label="Password"
                        updateImmediately
                        errorStyle={{position: 'absolute', top: 68}}
                        type="password"
                        onChange={this.handlePasswordChange}
                        value={this.props.user.password}
                    />
                </div>
            </Formsy>
            <div style={{marginTop: 12}}>
                <BlaButton
                    label="Next"
                    disabled={!formValid}
                    primary={true}
                    onClick={this.props.handleNext}
                    type="submit"
                />
            </div>
        </div>
        );
    }

}

export default RegistrationStep1
