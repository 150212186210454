import React, {Component} from 'react';
import {Table, TableBody, TableHead, TableCell, TableRow} from '@material-ui/core';

class AdminQuestionListDataContainer extends Component {
  getListItems = () => {
    return this.props.data.map(question => {

      return (
        <TableRow className="admin-row" key={question.id}>
          <TableCell>{question.question}</TableCell>
          <TableCell>{question.citation}</TableCell>
          <TableCell>{question.correct}</TableCell>
          <TableCell>{question.wrong_1}</TableCell>
          <TableCell>{question.wrong_2}</TableCell>
          <TableCell>{question.wrong_3}</TableCell>
          <TableCell>{question.category}</TableCell>
        </TableRow>
      )
    });
  }

  render() {
    let list = [];
    if(this.props.data && this.props.data.length) {
      list = this.getListItems();
    }
    return (
      <div>
        <Table selectable={false} showRowHover={false}>
          <TableHead displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow className="admin-list-header">
              <TableCell><h3 className={"bla-centered-paper-layoutTwo"}>QUESTION</h3></TableCell>
              <TableCell><h3 className={"bla-centered-paper-layoutTwo"}>CITATION</h3></TableCell>
              <TableCell><h3 className={"bla-centered-paper-layoutTwo"}>CORRECT</h3></TableCell>
              <TableCell><h3 className={"bla-centered-paper-layoutTwo"}>WRONG 1</h3></TableCell>
              <TableCell><h3 className={"bla-centered-paper-layoutTwo"}>WRONG 2</h3></TableCell>
              <TableCell><h3 className={"bla-centered-paper-layoutTwo"}>WRONG 3</h3></TableCell>
              <TableCell><h3 className={"bla-centered-paper-layoutTwo"}>CATEGORY</h3></TableCell>
            </TableRow>
          </TableHead>
          <TableBody displayRowCheckbox={false} showRowHover={false}>
            {list}
          </TableBody>
        </Table>
      </div>
    );
  }
}

AdminQuestionListDataContainer.propTypes = {};

export default AdminQuestionListDataContainer;
