import React, {Component} from 'react';
import PropTypes from 'prop-types';
import mapFetchToProps from '../util/mapFetchToProps';
import {connect} from 'react-redux';
import ExtensibleDataContainer from './base/BaseDataContainer';

let DataContainer = connect(mapFetchToProps)(ExtensibleDataContainer);

DataContainer.propTypes = { //for IDE. Delete if you want
	endpoint: PropTypes.string.isRequired,
	autoLoad: PropTypes.bool,
	id: PropTypes.string.isRequired,
	className: PropTypes.string,
	redirectUrl: PropTypes.string
};

export default DataContainer;
