import {
    ERROR_CLEARED, MESSAGE_CLEARED, ADD_MESSAGE, WAIT, UNWAIT
} from '../actions/uiActions';

import {
    REGISTRATION_RESPONSE, FACEBOOK_BUTTON_CLICKED,
    FACEBOOK_ERROR
} from '../actions/registrationActions';

import {
    UNKNOWN_ERROR, SCHEMA_ERROR, ADD_ERROR
} from '../actions/errorActions';
import {LOGIN_RESPONSE} from '../actions/loginActions';
import {LOG_OUT_USER} from '../actions/globalActions';
import initialState from '../state/initialState';

export default function ui(state = {}, action) {
    switch(action.type) {
        case FACEBOOK_BUTTON_CLICKED:
        case WAIT:
            return waitingReduction(state);
        case SCHEMA_ERROR:
        case UNKNOWN_ERROR:
        case ADD_ERROR:
            return errorReduction(action, state);
        case FACEBOOK_ERROR:
            return facebookErrorReduction(state);
        case ERROR_CLEARED:
            return clearErrorReduction(state);
        case MESSAGE_CLEARED:
            return clearMessageReduction(state);
        case REGISTRATION_RESPONSE:
        case LOGIN_RESPONSE:
        case UNWAIT:
            return unWait(state, action);
        case ADD_MESSAGE:
            return addMessageReduction(state, action);
        case LOG_OUT_USER:
            return logOutUserReduction();
        default:
            return state;
    }
}

function waitingReduction(state) {
    return Object.assign({}, state, {waiting: true});
}

function errorReduction(action, state) {
    var error = action.error;
    if(typeof error.message !== 'undefined') {
        error = error.message;
    }
    return Object.assign({}, state, {errorReceived: true, errorMessage: error, waiting: false});
}

function clearErrorReduction(state) {
    return Object.assign({}, state, {errorReceived: false, errorMessage: ""});
}

function addMessageReduction(state, action) {
    return Object.assign({}, state, {messageReceived: true, message: action.message});
}

function clearMessageReduction(state) {
    return Object.assign({}, state, {messageReceived: false, message: ""});
}

function facebookErrorReduction(state) {
    var error = "Unable to login to Facebook";
    return Object.assign({}, state, {errorReceived: true, errorMessage: error, waiting: false});
}

function unWait(state, action) {
    return Object.assign({}, state, {waiting: false});
}

function logOutUserReduction() {
    return initialState.ui;
}
