
export const SCHEMA_RESPONSE = 'SCHEMA_RESPONSE';
export function schemaResponse(json, id) {
	return {
		type: SCHEMA_RESPONSE,
		response: json,
		id: id
	}
}

export const SCHEMA_REQUEST = 'SCHEMA_REQUEST';
export function schemaRequest(id) {
	return {
		type: SCHEMA_REQUEST,
		id: id,
		inProgress: true
	}
}

export const SCHEMA_AUTOSUGGEST_REQUEST_COMPLETED = 'SCHEMA_AUTOSUGGEST_REQUEST_COMPLETED';
export function schemaAutoSuggestRequestCompleted(json, id, property) {
	return {
		type: SCHEMA_AUTOSUGGEST_REQUEST_COMPLETED,
		id,
		property,
		response: json
	}
}

export const SCHEMA_REQUEST_COMPLETED = 'SCHEMA_REQUEST_COMPLETED';
export function schemaRequestCompleted(id) {
	return {
		type: SCHEMA_REQUEST_COMPLETED,
		id: id,
		inProgress: false
	}
}

export const WIPE_SCHEMA_FORM = 'WIPE_SCHEMA_FORM';
export function wipeSchemaForm(id) {
	return {
		type: WIPE_SCHEMA_FORM,
		id: id
	}
}

export const REMOVE_ENTITY_FROM_AUTOLOADER_LIST = 'REMOVE_ENTITY_FROM_AUTOLOADER_LIST';
export function removeEntityFromAutoloaderList(autoLoaderId, entityId) {
	return {
		type: REMOVE_ENTITY_FROM_AUTOLOADER_LIST,
		autoLoaderId: autoLoaderId,
		entityId: entityId
	}
}

export const AUTOLOADING_FORM_EDITED = 'AUTOLOADING_FORM_EDITED';
export function autoloadingFormEdited(autoLoaderId, property, newValue) {
	return {
		type: AUTOLOADING_FORM_EDITED,
		autoLoaderId:autoLoaderId,
		property: property,
		newValue: newValue
	}
}

export const ANSWER_SELECTED = 'ANSWER_SELECTED';
export function answerSelected(id, questionIndex, answerId) {
	return {
		type: ANSWER_SELECTED,
		autoLoaderId: id,
		questionIndex,
		answerId
	}
}

export const RELATED_AUTO_LOADING_FORM_EDITED = 'RELATED_AUTO_LOADING_FORM_EDITED';
export function relatedAutoLoadingFormEdited(autoLoaderId, property, newValue) {
	return {
		type: RELATED_AUTO_LOADING_FORM_EDITED,
		autoLoaderId: autoLoaderId,
		property: property,
		newValue: newValue
	}
}

export const GIVE_INITIAL_DATA = 'GIVE_INITIAL_DATA';
export function giveInitialData(autoLoaderId, data) {
	return {
		type: GIVE_INITIAL_DATA,
		autoLoaderId: autoLoaderId,
		data: data
	}
}

export const REPLACE_CURRENT_USER = 'REPLACE_CURRENT_USER';
export function replaceCurrentUser(user) {
	return {
		type: REPLACE_CURRENT_USER,
		user: user
	}
}