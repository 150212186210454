import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {TableRow, TableCell,} from "@material-ui/core";
import AppRoutes from '../../../config/AppRoutes';


class GeolocationUsersListItem extends Component {
  render() {
    const testLink = AppRoutes.GEOLOCATION_USER_DETAIL.replace(':id', this.props.data.id);
    
    return (
      
      <TableRow className="admin-row" hidden={this.props.hidden}>
          <TableCell>
            <Link to={testLink}>{this.props.data.email}</Link>
          </TableCell>
      </TableRow>
    );
  }
}

GeolocationUsersListItem.propTypes = {
  data: PropTypes.object.isRequired
};

export default GeolocationUsersListItem;
