import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { getAccessToken, getUserId } from '../../util/storage/accessTokenStorage';
import AppRoutes from "../../config/AppRoutes";
import RadioButton from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import BigLayout from "../../layouts/BigLayout";
import BlaButton from "../../ui/buttons/BlaButton";
import {connect} from "react-redux";
import {addMessage} from "../../actions/uiActions";
import redirectPartialUpdate from "../../util/forms/redirectPartialUpdate";
import ApiConfig from "../../config/ApiConfig";
import {answerSelected, schemaRequestCompleted} from "../../actions/schemaActions";
import { FormControlLabel } from '@material-ui/core';
import fetch from 'isomorphic-fetch';

class TestView extends Component {

    constructor() {
        super();
        this.state = {
          canSubmit: true,
          showAnswer: false
        }
    }

    componentDidMount() {
      let testNode = document.getElementById('testPage');
      if(!testNode) {
        testNode = document.getElementById('testReviewPage');
      }
      testNode.oncopy = function() { return false; };
      testNode.oncut = function() { return false; };
      testNode.onpaste = function() { return false; };
      testNode.oncontextmenu = function() { return false; };
    }

    get SaveButton() {
      if(this.props.reviewMode) {
        return null;
      }
      return <BlaButton disabled={false} onTouchTap={this.exitWithMessage} label="Save" />;
    }

    static propTypes = {
        questionIndex: PropTypes.string.isRequired,
        reviewMode: PropTypes.bool.isRequired
    };

    shouldIShowAnswer = () => {
      if(this.props.reviewMode) {
        return true;
      }
      return (this.state.showAnswer || this.props.data.testQuestions[this.props.questionIndex].answer !== null);
    };

    isAnswerCorrect = () => {
      let testQuestion = this.props.data.testQuestions[this.props.questionIndex];
      if(testQuestion.answer) {
        return testQuestion.answer.isCorrect === "1"
      }
      return testQuestion.question.answers[this.getDefaultSelected()] && testQuestion.question.answers[this.getDefaultSelected()].isCorrect === "1";
    };

    getTitle = () => {
      if(!this.shouldIShowAnswer()) {
        console.log(this.props.data.testType);
        const testType = (this.props.data.testType && this.props.data.testType.includes('General Orders')) ? 'General Orders' : this.props.data.testType;
        return testType + " Test";
      }
      if(this.isAnswerCorrect()) {
        return 'Correct';
      }
      return 'Incorrect';
    };

    isQuestionAnsweredAlready = () => {
      if(this.props.reviewMode) {
        return true;
      }
      let testQuestion = this.props.data.testQuestions[this.props.questionIndex];
      return !!testQuestion.answer || (this.state.showAnswer && !!testQuestion.answerId);
    };

    getRadioButton = (index) => {
      let question = this.props.data.testQuestions[this.props.questionIndex].question;
      let testQuestion = this.props.data.testQuestions[this.props.questionIndex];
      return (
        <FormControlLabel
          className="bla-radio-label"
          control={
            <RadioButton
              className={(index === this.getCorrectIndex() && (this.shouldIShowAnswer())) ? "show-correct answer-input" : "answer-input"}
              key={index}
              disabled={this.isQuestionAnsweredAlready()}
            />
          }
          label={question.answers[index].text}
          value={index}
        />
      );
    };

    getCorrectIndex = () => {
      const testQuestion = this.props.data.testQuestions[this.props.questionIndex];
      let correctIndex = 0;
      testQuestion.question.answers.forEach((answer, index) => {
        if(answer.columnName === 'Correct Answer') {
          correctIndex = index;
        }
      });
      return correctIndex;
    };

    getIndex = (orderList, i) => {
      const testQuestion = this.props.data.testQuestions[this.props.questionIndex];
      let letter = orderList[i];
      let correctIndex = 0;
      if(letter === 'A') {
        testQuestion.question.answers.forEach((answer, index) => {
          if(answer.columnName === 'Correct Answer') {
            correctIndex = index;
          }
        });
        return correctIndex;
      }
      if(letter === 'B') {
        testQuestion.question.answers.forEach((answer, index) => {
          if(answer.columnName === 'Wrong Answer 1') {
            correctIndex = index;
          }
        });
        return correctIndex;
      }
      if(letter === 'C') {
        testQuestion.question.answers.forEach((answer, index) => {
          if(answer.columnName === 'Wrong Answer 2') {
            correctIndex = index;
          }
        });
        return correctIndex;
      }
      if(letter === 'D') {
        testQuestion.question.answers.forEach((answer, index) => {
          if(answer.columnName === 'Wrong Answer 3') {
            correctIndex = index;
          }
        });
        return correctIndex;
      }
      throw new Error('unknown order letter');
    };

    getDefaultSelected = () => {
      //find the index of the answer
      let testQuestion = this.props.data.testQuestions[this.props.questionIndex];
      if(testQuestion.answerId === null) {
        return null;
      }
      let answers = testQuestion.question.answers;
      let answer;
      for(let i = 0; i < 4; i++) {
        answer = answers[i];
        if(answer.id === testQuestion.answerId) {
          return i;
        }
      }
      return null;
    };

    getAnswerId = (index) => {
      let answer = this.props.data.testQuestions[this.props.questionIndex].question.answers[index];
      return answer.id;
    };

    getRadioGroup = () => {
      let order = this.props.data.testQuestions[this.props.questionIndex].order;
      let orderList = order.split(',');
      let radioButtons = [];
      for(let i = 0; i < 4 ; i++ ) {
        radioButtons.push(this.getRadioButton(this.getIndex(orderList, i)))
      }
      return(
        <RadioGroup
          name="answer"
          value={this.getDefaultSelected()}
          onChange={(event, value) => this.props.dispatch(answerSelected(this.props.id, this.props.questionIndex, this.getAnswerId(value)))} >
          {radioButtons}
        </RadioGroup>
      );
    };

    isFirstQuestion = () => {
      return this.props.questionIndex === '0';
    };

    exitWithMessage = () => {
      this.props.dispatch(addMessage('Test Saved!'));
      this.props.history.push(AppRoutes.TEST_SELECTION_PAGE.replace(':id', getUserId()))
    };

    isLastQuestion = () => {
      let count = this.props.data.testQuestions.length;
      return count === parseInt(this.props.questionIndex) + 1;
    };

    nextButtonClicked = (e) => {
      e.stopPropagation();
      e.preventDefault();
      if(this.isQuestionAnsweredAlready() && !this.isLastQuestion()) {
        this.setState({
          showAnswer: false
        });
        setTimeout(() => this.props.history.push(this.nextTestUrl), 200);

      }
      else if(this.isLastQuestion() && this.shouldIShowAnswer()) {
        if(navigator && navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            const accuracy = position.coords.accuracy;
            const geolocationType = 'Test End';
            const payload = { lat, lng, accuracy, type: geolocationType };
  
            const endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.geolocation;
  
            const bodyJson = JSON.stringify(payload);
            fetch(endpoint, {
              method: 'POST',
              mode: 'cors',
              credentials: 'include',
              headers: {
                'Authorization': 'Bearer ' + getAccessToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: bodyJson
            })
          })
        }
        this.props.history.push(this.testWrapUpUrl)
      }
      else {
        this.submitAnswer();
      }
    };

    getCurrentlySelectedAnswerId = () => {
      return this.props.data.testQuestions[this.props.questionIndex].answerId;
    };

    getCurrentTestQuestionId = () => {
      let question = this.props.data.testQuestions[this.props.questionIndex];
      return question.id;
    };

    showAnswer = (json, dispatch, id, property) => {
      this.props.dispatch(schemaRequestCompleted(this.props.id));
      this.setState({
        showAnswer: true
      });
    };

    submitAnswer = () => {
      let testQuestion = {
        id: this.getCurrentTestQuestionId(),
        answerId: this.getCurrentlySelectedAnswerId()
      };

      redirectPartialUpdate(
        this,
        testQuestion,
        null,
        ApiConfig.protocol + ApiConfig.apiUri +  ApiConfig.testQuestionEndpoint,
        this.currentTestUrl,
        this.showAnswer
      );
    };

    get nextTestUrl() {
      if(this.props.reviewMode) {
        return AppRoutes.TEST_REVIEW_PAGE.replace(':id', this.props.data.id).replace(':index', parseInt(this.props.questionIndex) + 1)
      }
      return AppRoutes.TEST_PAGE.replace(':id', this.props.data.id).replace(':index', parseInt(this.props.questionIndex) + 1);
    }

    get previousTestUrl() {
      if(this.props.reviewMode) {
        return AppRoutes.TEST_REVIEW_PAGE.replace(':id', this.props.data.id).replace(':index', parseInt(this.props.questionIndex) - 1);
      }
      return AppRoutes.TEST_PAGE.replace(':id', this.props.data.id).replace(':index', parseInt(this.props.questionIndex) - 1);
    }

    get currentTestUrl() {
      return AppRoutes.TEST_PAGE.replace(':id', this.props.data.id).replace(':index', parseInt(this.props.questionIndex));
    }

    get testWrapUpUrl() {
      return AppRoutes.TEST_WRAP_UP_PAGE.replace(':id', this.props.data.id);
    }

    get nextButtonLabel() {
      return (this.isLastQuestion() && this.shouldIShowAnswer()) ? 'Finish' : 'Next';
    }

    goBack = () => {
      this.setState({
        showAnswer: true
      });
      this.props.history.push(this.previousTestUrl);
    };

    isNextButtonDisabled = () => {
      if(this.props.reviewMode) {
        return false;
      }
      return !this.getCurrentlySelectedAnswerId();
    };
  
   

    render() {
      let question = {text: ''};
      if(this.props.data.testQuestions) {
          question = this.props.data.testQuestions[this.props.questionIndex].question;
      }
      
      
      const citationClasses = (question.testType === 'Sergeant 2020' || question.testType === 'Sergeant Combined 2020')
        ? 'question-citation left-justify' : 'question-citation';


      if(question.text) {
        return (
          <BigLayout>
            <h2 className="bla-centered-paper-layout test-header">{parseInt(this.props.questionIndex) + parseInt(1) + " - " + this.getTitle()}</h2>
            <div className="castle-form">
              <div className="question-wrapper">
                  <div className="question-text">
                    {question.text}
                  </div>
              </div>
              <div className={citationClasses}>
                {question.citation}
              </div>
              <div className="answer-wrapper">
                {this.getRadioGroup()}
              </div>
              <div className="flex-container answer-action-container">
                <BlaButton disabled={this.isFirstQuestion()} onClick={this.goBack} label="Back" />
                <BlaButton disabled={this.isNextButtonDisabled()} onClick={this.nextButtonClicked} label={this.nextButtonLabel} />
              </div>
            </div>
          </BigLayout>
        );
      }
      else {
          return <div>Loading...</div>
      }
    }
}

export default connect()(TestView);
