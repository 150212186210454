export const SCHEMA_ERROR = 'SCHEMA_ERROR';
export function schemaError(error) {
    console.error(error);
    return {
        type: SCHEMA_ERROR,
        error: error
    }
}

export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';
export function unknownError(error) {
    console.error(error);
    return {
        type: UNKNOWN_ERROR,
        error: {message: 'An unknown error occurred'}
    }
}

export const ADD_ERROR = 'ADD_ERROR';
export function addError(errorMessage) {
    console.error(errorMessage);
    return {
        type: ADD_ERROR,
        error: {message: errorMessage}
    }
}


