import { combineReducers } from 'redux';
import currentUser from './currentUser';
import ui from './ui';
import autoLoaders from './autoLoaders';

const rootReducer = combineReducers({
    ui,
    currentUser,
    autoLoaders
});

export default rootReducer;