import React, {Component} from 'react';
import ApiConfig from '../../config/ApiConfig';
import EditDataContainer from '../../containers/EditDataContainer';
import AppRoutes from '../../config/AppRoutes';
import {getUserId} from '../../util/storage/accessTokenStorage';
import ResetPasswordView from '../views/ResetPasswordView';

class ResetPasswordPage extends Component {
  render() {
    let endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.verifyResetHash + '/' + this.props.match.params.email + '/' + this.props.match.params.hash;
    let requestEndpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.passwordResetEndpoint;

    return (
      <EditDataContainer
        id="resetPage"
        endpoint={endpoint}
        requestEndpoint={requestEndpoint}
        redirectUrl={AppRoutes.TEST_WRAP_UP_PAGE.replace(":id", getUserId(this.props.match.params.id))}
      >
        <ResetPasswordView
          history={this.props.history}
          email={this.props.match.params.email}
          hash={this.props.match.params.hash}
        />
      </EditDataContainer>
    )
  }
}

ResetPasswordPage.propTypes = {};

export default ResetPasswordPage;
