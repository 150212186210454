import React, {Component} from 'react';
import ListDataContainer from '../../containers/ListDataContainer';
import NoData from '../partials/empty_data/NoData';
import AdminListItem from '../partials/list_items/AdminListItem';
import AppRoutes from '../../config/AppRoutes';
import ApiConfig from '../../config/ApiConfig';
import BigLayout from '../../layouts/BigLayout';
import AdminNavigation from '../partials/navigation/AdminNavigation';

class AdminTestListPage extends Component {
  render() {
    const endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.testEndpoint + 'admin';
    return (
      <div>
        <AdminNavigation history={this.props.history} page="Test List" />
        <BigLayout>
          <h2 className={"bla-centered-paper-layout"}>TEST ADMINISTRATION</h2>
          <ListDataContainer
            id="adminPage"
            endpoint={endpoint}
            sortControls={true}
            search={true}
            noData={NoData}
            itemComponent={AdminListItem}
            createUrl={AppRoutes.ADMIN_PAGE}
          />
        </BigLayout>
      </div>
    );
  }
}

AdminTestListPage.propTypes = {};

export default AdminTestListPage;
