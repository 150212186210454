import React, {Component} from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import Formsy from 'formsy-react';
import BlaButton from "../../../ui/buttons/BlaButton";

class RegistrationStep2 extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            formValid: false
        };
    }

    handleValid = () => {
        //this.setState({formValid: true});
    };

    handleInvalid = () => {
        //this.setState({formValid: false});
    };

    acceptTerms = () => {
        this.setState({
            formValid: !this.state.formValid
        });
    };

    render() {
        let {formValid} = this.state;
        let requiredError = "This field is required";
        return<div>
            <Formsy
                onValid={this.handleValid}
                onInvalid={this.handleInvalid}
            >
                <div>
                    <h3 className={"bla-centered-paper-layoutTwo"}>REGISTER</h3>
                    <div className="castle-registration-checkbox-text">
                        <span>
                          I accept the&nbsp;
                            <a
                              className={"bla-hype-style"}
                              onClick={(event) => event.stopPropagation()}
                              href="https://www.bluelineadvances.com/terms-and-conditions.html"
                              target="_blank">
                              terms and conditions
                          </a>
                        </span>
                        <div className="castle-registration-checkbox">
                            <Checkbox
                              className="registration-checkbox"
                              label=""
                              onChange={this.acceptTerms}
                            />
                        </div>
                    </div>
                </div>
                <div style={{marginTop: 12, display: 'flex', justifyContent: 'center'}}>
                    <BlaButton
                      label="Finish"
                      primary={true}
                      onClick={this.props.handleFinalSubmission}
                      type="submit"
                      disabled={!formValid}
                    />
                </div>
            </Formsy>
        </div>;
    }

}

export default RegistrationStep2
