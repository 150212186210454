import React, {Component} from 'react';
import CenteredPaperLayout from '../../layouts/CenteredPaperLayout';
import Formsy from 'formsy-react';
import FormsyText from '../../components/formsy/FormsyText';
import BlaButton from '../../ui/buttons/BlaButton';
import {doPasswordResetRequest, doRedirectCreate} from '../../actions/thunks/schemaThunks';
import ApiConfig from '../../config/ApiConfig';
import {connect} from 'react-redux';
import {addMessage, unWait, wait} from '../../actions/uiActions';
import AppRoutes from '../../config/AppRoutes';
import {createBrowserHistory} from 'history';
const browserHistory = createBrowserHistory();

//TODO: Formsy
/*Formsy.addValidationRule('passwordsMustMatch', (values, value) => {
  return values.passwordOne === values.passwordTwo;
});

Formsy.addValidationRule('passwordMustBeGreaterThanSixCharacters', (values, value) => {
  return value.length > 6;
});*/

class ResetPasswordView extends Component {

  state = {
    valid: false,
    passwordOne: '',
    passwordTwo: ''
  };

  isFormValid = () => {
    return this.state.valid && this.state.passwordOne === this.state.passwordTwo;
  };

  handleValid = () => {
    this.setState({ valid: true });
  };

  handleInvalid = () => {
    this.setState({ valid: false });
  };

  handlePasswordOneChange = (e) => {
    this.setState({ passwordOne: e.target.value });
  };

  handlePasswordTwoChange = (e) => {
    this.setState({ passwordTwo: e.target.value });
  };

  makeResetRequest = () => {
    console.log(this.props.email);
    const payload = {
      password: this.state.passwordOne,
      email: this.props.email,
      hash: this.props.hash
    };
    const endpoint = ApiConfig.protocol + ApiConfig.apiUri +  ApiConfig.passwordResetEndpoint;
    this.props.dispatch(doPasswordResetRequest(endpoint, payload, this.addMessageAndRedirectHome));
    this.props.dispatch(wait());
    this.setState({
      passwordOne: '',
      passwordTwo: ''
    });
  };

  addMessageAndRedirectHome = () => {
    this.props.dispatch(unWait());
    this.props.dispatch(addMessage("Password Changed Successfully!"));
    this.props.history.push(AppRoutes.DEFAULT_ROUTE);
  };

  lengthMessage = "password must be longer than six characters";

  matchMessage = "passwords must match";

  render() {
    return (
      <CenteredPaperLayout>
        <h2 className={"bla-centered-paper-layout"}>RESET PASSWORD</h2>
        <div className="reset-password-main-wrapper" >
          <Formsy
            onValid={this.handleValid}
            onInvalid={this.handleInvalid}
          >
            <div>
              <FormsyText
                name="passwordOne"
                validations="minLength:7"
                validationError={this.lengthMessage}
                required
                label="Password"
                type="password"
                onChange={this.handlePasswordOneChange}
                value={this.state.passwordOne}
              />
            </div>

            <div>
              <FormsyText
                name="passwordTwo"
                validations="equalsField:passwordOne"
                validationError={this.matchMessage}
                required
                label="Password"
                type="password"
                onChange={this.handlePasswordTwoChange}
                value={this.state.passwordTwo}
              />
            </div>
            <BlaButton
              onClick={this.makeResetRequest}
              disabled={!this.isFormValid()}
              label="Reset Password"
            />
          </Formsy>
        </div>
      </CenteredPaperLayout>
    );
  }
}

ResetPasswordView.propTypes = {};

export default connect()(ResetPasswordView);

/**
 <div>
 <FormsyText
 name="passwordOne"
 validations="passwordMustBeGreaterThanSixCharacters"
 validationError={this.lengthMessage}
 required
 floatingLabelText="Password"
 floatingLabelFixed={true}
 updateImmediately
 type="password"
 onChange={this.handlePasswordOneChange}
 value={this.state.passwordOne}
 />
 </div>
 
 <div>
 <FormsyText
 name="passwordTwo"
 validations="passwordsMustMatch"
 validationError={this.matchMessage}
 required
 floatingLabelText="Password"
 floatingLabelFixed={true}
 updateImmediately
 type="password"
 onChange={this.handlePasswordTwoChange}
 value={this.state.passwordTwo}
 />
 </div>
 **/
