import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import AppRoutes from '../../../config/AppRoutes';


class TestListListItem extends Component {

    render() {
        const className = this.props.data.is_complete === '1' ? 'test-list-item-row already-taken' : 'test-list-item-row';
        const lastColumnClassName = this.props.data.is_complete === '1' ? 'last-column-already-taken' : '';

        const url = this.props.data.is_complete === '1' ?
          AppRoutes.TEST_WRAP_UP_PAGE.replace(':id', this.props.data.id) : //.replace(':index', '0') :
          AppRoutes.TEST_PAGE.replace(':id', this.props.data.id).replace(':index', this.props.data.index);

        return (
            <TableRow
              className={className}
              onClick={() => this.props.history.push(url)}
              hidden={this.props.hidden}
            >
                <TableCell>{this.props.data.test_type}</TableCell>
                <TableCell>{this.props.data.score}</TableCell>
                <TableCell className={lastColumnClassName}>{this.props.data.created_on}</TableCell>
            </TableRow>
        );
    }
}

TestListListItem.propTypes = {
    data: PropTypes.object.isRequired,
    isDividerHidden: PropTypes.bool
};

export default TestListListItem;
