import {getAccessToken} from "../storage/accessTokenStorage";
import {doRedirectFullUpdate, doRedirectPartialUpdate} from "../../actions/thunks/schemaThunks";
import {schemaRequest} from "../../actions/schemaActions";

const redirectPartialUpdate = (component, payload = null, message = null, requestEndpoint = null, redirectUrl = null, callback = () => {}) => {
  component.props.dispatch(schemaRequest(component.props.id));
  const data = payload || component.props.data;
  const endpoint = requestEndpoint || component.props.requestEndpoint;
  const url = redirectUrl || component.props.deleteRedirectUrl;
  const accessToken = component.props.accessToken || getAccessToken();

  component.props.dispatch(
    doRedirectPartialUpdate(accessToken, endpoint, url, data, null, component.props.id, callback)
  );
};

export default redirectPartialUpdate;
