import React, {Component} from 'react';
import ListDataContainer from '../../containers/ListDataContainer';
import AppRoutes from '../../config/AppRoutes';
import ApiConfig from '../../config/ApiConfig';
import {connect} from "react-redux";
import {
  doSyncGoQuestions, doSyncCamQuestions, doSyncErpQuestions, syncAuthorization,
  doSyncGoCaptainQuestions, doSyncGoSergeantQuestions, doSyncCommanderQuestions, doSyncSergeant2024Questions, doSyncSergeantCombined2020Questions
} from '../../actions/thunks/syncThunks';
import {getAccessToken, getCurrentUser} from '../../util/storage/accessTokenStorage';
import BlaButton from "../../ui/buttons/BlaButton";
import DataContainer from '../../containers/DataContainer';
import SyncButtonsView from '../views/SyncButtonsView';
import {addMessage, unWait} from '../../actions/uiActions';
import {schemaError} from '../../actions/errorActions';
import {schemaRequest} from '../../actions/schemaActions';
import {doSchemaGet} from '../../actions/thunks/schemaThunks';
import AdminNavigation from '../partials/navigation/AdminNavigation';
import {logOutUser} from '../../actions/globalActions';

class AdminPage extends Component {
    syncGoQuestions = () => {
        const accessToken = getAccessToken();
        this.props.dispatch(doSyncGoQuestions(accessToken, this.handleSuccessfulQuestionSync))
    };

    syncCamQuestions = () => {
        const accessToken = getAccessToken();
        this.props.dispatch(doSyncCamQuestions(accessToken, this.handleSuccessfulQuestionSync))
    };

    syncErpQuestions = () => {
        const accessToken = getAccessToken();
        this.props.dispatch(doSyncErpQuestions(accessToken, this.handleSuccessfulQuestionSync))
    };

    doSyncAuthorization = () => {
        const accessToken = getAccessToken();
        this.props.dispatch(syncAuthorization(accessToken, this.handleSuccessfulAuthorizationSync));
    };

    syncGoCaptainQuestions = () => {
        const accessToken = getAccessToken();
        this.props.dispatch(doSyncGoCaptainQuestions(accessToken, this.handleSuccessfulQuestionSync));
    };

    syncGoSergeantQuestions = () => {
        const accessToken = getAccessToken();
        this.props.dispatch(doSyncGoSergeantQuestions(accessToken, this.handleSuccessfulQuestionSync));
    };
  
    syncCommanderQuestions = () => {
      const accessToken = getAccessToken();
      this.props.dispatch(doSyncCommanderQuestions(accessToken, this.handleSuccessfulQuestionSync));
    };
  
    syncSergeant2024Questions = () => {
      const accessToken = getAccessToken();
      this.props.dispatch(doSyncSergeant2024Questions(accessToken, this.handleSuccessfulQuestionSync));
    };
  
    syncSergeantCombined2020Questions = () => {
      const accessToken = getAccessToken();
      this.props.dispatch(doSyncSergeantCombined2020Questions(accessToken, this.handleSuccessfulQuestionSync));
    };

    handleSuccessfulQuestionSync = (json) => {
        this.props.dispatch(unWait());
        if(!json.error) {
          const accessToken = getAccessToken();
          this.props.dispatch(addMessage("Questions Synced!"));
          this.props.dispatch(schemaRequest("syncButtonView"));
          this.props.dispatch(doSchemaGet("syncButtonView", accessToken, ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.lastSyncs));
        }
        else { //Needed for OAuth Server's error handling :(
          if(json.error_description) {
            let error = {message: json.error_description};
            this.props.dispatch(schemaError(error));
          }
          else {
            this.props.dispatch(schemaError(json));
          }
        }
    };

    handleSuccessfulAuthorizationSync = (json) => {
        this.props.dispatch(unWait());
        if(!json.error) {
            const accessToken = getAccessToken();
            this.props.dispatch(addMessage("Authorization Synced!"));
            this.props.dispatch(schemaRequest("syncButtonView"));
            this.props.dispatch(doSchemaGet("syncButtonView", accessToken, ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.lastSyncs));
        }
        else { //Needed for OAuth Server's error handling :(
            if(json.error_description) {
                let error = {message: json.error_description};
                this.props.dispatch(schemaError(error));
            }
            else {
                this.props.dispatch(schemaError(json));
            }
        }
    }

    validateAdminUser = (user) => {
        if(user.user.isAdmin !== '1') {
           this.props.dispatch(logOutUser(this.props.history));
        }
    };

    render() {
        const user = getCurrentUser();
        this.validateAdminUser(user);
        let dataContainerEndpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.lastSyncs;

        return (
            <div>
              <AdminNavigation history={this.props.history} page="Home" />
              <DataContainer
                endpoint={dataContainerEndpoint}
                id="syncButtonView"
              >
                <SyncButtonsView
                  doSyncGoSergeantQuestions={this.syncGoSergeantQuestions}
                  syncGoQuestions={this.syncGoQuestions}
                  syncCamQuestions={this.syncCamQuestions}
                  syncErpQuestions={this.syncErpQuestions}
                  syncCommander={this.syncCommanderQuestions}
                  doSyncSergeant2024Questions={this.syncSergeant2024Questions}
                  doSyncSergeantCombined2020Questions={this.syncSergeantCombined2020Questions}
                  doSyncAuthorization={this.doSyncAuthorization}
                  doSyncGoCaptainQuestions={this.syncGoCaptainQuestions}
                />
              </DataContainer>
            </div>
        )
    }
}

export default connect()(AdminPage);
