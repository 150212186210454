import {
  SCHEMA_RESPONSE, SCHEMA_AUTOSUGGEST_REQUEST_COMPLETED, REMOVE_ENTITY_FROM_AUTOLOADER_LIST,
  RELATED_AUTO_LOADING_FORM_EDITED, ANSWER_SELECTED
} from '../actions/schemaActions';
import {SCHEMA_REQUEST, SCHEMA_REQUEST_COMPLETED, AUTOLOADING_FORM_EDITED, GIVE_INITIAL_DATA} from '../actions/schemaActions';
import {LOG_OUT_USER} from '../actions/globalActions';
import initialState from '../state/initialState';
//import {REHYDRATE} from 'redux-persist/constants' //TODO: //TODO: fix rehydrate reducer (look into persistReducer(config, reducer))

function logOutUserReduction() {
	return initialState.autoLoaders;
}

function relatedAutoLoadingFormEditedReduction (state, action) {
	var newState = JSON.parse(JSON.stringify(state));
	const autoLoaderId = action.autoLoaderId;
	const property = action.property;
	const newValue = action.newValue;
	
	if(typeof newState.loaders[autoLoaderId] === 'undefined') {
		newState.loaders[autoLoaderId] = {};
	}
	
	// if property ends in "Id" and newValue is null and there exists an object at the index where the rest of the word is,
	// then remove that object
	if(property.endsWith("Id") && newValue === null && newState.loaders[autoLoaderId][property.slice(0, -2)] &&
		(newState.loaders[autoLoaderId][property.slice(0, -2)] !== null &&
			typeof newState.loaders[autoLoaderId][property.slice(0, -2)] === 'object')) {
		newState.loaders[autoLoaderId][property.slice(0, -2)] = null;
	}
	
	newState.loaders[autoLoaderId][property] = Object.assign(newState.loaders[autoLoaderId][property], newValue);
	
	return newState;
}

function autoloadingFormEditedReduction(state, action) {
	var newState = JSON.parse(JSON.stringify(state));
	const autoLoaderId = action.autoLoaderId;
	const property = action.property;
	const newValue = action.newValue;
	
	if(typeof newState.loaders[autoLoaderId] === 'undefined') {
		newState.loaders[autoLoaderId] = {};
	}
	
	// if property ends in "Id" and newValue is null and there exists an object at the index where the rest of the word is,
	// then remove that object
	if(property.endsWith("Id") && newValue === null && newState.loaders[autoLoaderId][property.slice(0, -2)] &&
		(newState.loaders[autoLoaderId][property.slice(0, -2)] !== null &&
			typeof newState.loaders[autoLoaderId][property.slice(0, -2)] === 'object')) {
		newState.loaders[autoLoaderId][property.slice(0, -2)] = null;
	}
	
	
	newState.loaders[autoLoaderId][property] = newValue;
	
	return newState;
}

function giveInitialDataReduction(state, action) {
	var newState = JSON.parse(JSON.stringify(state));
	const autoLoaderId = action.autoLoaderId;
	
	newState.loaders[autoLoaderId] = action.data;
	return newState;
}

export default function autoLoaders(state = {}, action) {
	switch(action.type) {
		case SCHEMA_RESPONSE:
			return userListResponseReduction(state, action);
		case SCHEMA_AUTOSUGGEST_REQUEST_COMPLETED:
			return schemaAutoSuggestRequestCompleted(state, action);
		case SCHEMA_REQUEST:
		case SCHEMA_REQUEST_COMPLETED:
			return schemaRequestReduction(state, action);
		case LOG_OUT_USER:
			return logOutUserReduction();
		case REMOVE_ENTITY_FROM_AUTOLOADER_LIST:
			return removeEntityFromAutoLoaderList(state, action);
		case AUTOLOADING_FORM_EDITED:
			return autoloadingFormEditedReduction(state, action);
		case ANSWER_SELECTED:
			return answerSelected(state, action);
		case RELATED_AUTO_LOADING_FORM_EDITED:
			return relatedAutoLoadingFormEditedReduction(state, action);
		case GIVE_INITIAL_DATA:
			return giveInitialDataReduction(state, action);
		case 'REHYDRATE':
			if(state.timestamp && (state.timestamp > action.payload.autoLoaders.timestamp)) {
				return state;
			}
			if(action.payload.autoLoaders) {
				return action.payload.autoLoaders;
			}
			return state;
		default:
			return state;
	}
}

function answerSelected(state, action) {
	const autoLoaderId = action.autoLoaderId;
	let newState = JSON.parse(JSON.stringify(state));
	const questionIndex = action.questionIndex;
	newState.loaders[autoLoaderId].testQuestions[questionIndex].answerId = action.answerId;
	return newState;
}

function removeEntityFromAutoLoaderList(state, action) {
	const entityId = action.entityId;
	const autoLoaderId = action.autoLoaderId;
	
	var newState = JSON.parse(JSON.stringify(state));
	var currentLoaders = newState.loaders;
	if(typeof currentLoaders[autoLoaderId] !== 'undefined') {
		var loader = currentLoaders[autoLoaderId];
		if(loader.length) {
			for(let[index, entity] of loader.entries()) {
				if(entity.id == entityId) {
					loader.splice(index, 1);
				}
			}
		}
	}
	return newState;
}

function userListResponseReduction(state, action) {
	var response = action.response;
	var autoLoaderId = action.id;
	var currentLoaders = Object.assign({}, state.loaders);
	currentLoaders[autoLoaderId] = response;
	return Object.assign({}, state, {loaders: currentLoaders, timestamp: Date.now()});
}

function schemaAutoSuggestRequestCompleted(state, action) {
	var newState = JSON.parse(JSON.stringify(state));
	const autoLoaderId = action.id;
	const property = action.property;
	const response = action.response;
	
	if(typeof newState.suggesters === 'undefined') {
		newState.suggesters = {};
	}
	
	if(typeof newState.suggesters[autoLoaderId] === 'undefined') {
		newState.suggesters[autoLoaderId] = {};
	}
	
	newState.suggesters[autoLoaderId][property] = response;
	return newState;
}

function schemaRequestReduction(state, action) {
	let autoLoaderId = action.id;
	
	var loadingList = Object.assign({}, state.loadingList);
	loadingList[autoLoaderId] = action.inProgress;
	return Object.assign({}, state, {loadingList: loadingList});
}
