import AppRoutes from '../config/AppRoutes';
import TestPage from '../pages/schema/TestPage';
import TestReviewPage from '../pages/schema/TestReviewPage';
import TestSelectionPage from '../pages/schema/TestSelectionPage';
import TestWrapUpPage from '../pages/schema/TestWrapUpPage';
import TestListPage from '../pages/schema/TestListPage';
import AdminPage from '../pages/schema/AdminPage';
import {getCurrentUser, getUserId} from './storage/accessTokenStorage';
import TestTermsPage from "../pages/schema/TestTermsPage";
import ResetPasswordRequestPage from "../pages/schema/ResetPasswordRequestPage";
import ResetPasswordPage from '../pages/schema/ResetPasswordPage';
import AdminTestListPage from '../pages/schema/AdminTestListPage';
import AdminQuestionsPage from '../pages/schema/AdminQuestionsPage';
import GeneralOrdersPage from '../pages/schema/GeneralOrdersPage';
import GeolocationUsers from '../pages/schema/GeolocationUsers';
import GeolocationUserDetail from '../pages/schema/GeolocationUserDetail';
let user = getCurrentUser();

export default {
    TEST_PAGE: {url: AppRoutes.TEST_PAGE, name: 'Test', component: TestPage, isNav: 0},
    TEST_REVIEW_PAGE: {url: AppRoutes.TEST_REVIEW_PAGE, name: 'Test Review', component: TestReviewPage, isNav: 0},
    TEST_SELECTION_PAGE: {url: AppRoutes.TEST_SELECTION_PAGE.replace(':id',
      (getCurrentUser() && getCurrentUser().user) ? getCurrentUser().user.authorizationId : ''), name: 'Test Select', component: TestSelectionPage, isNav: 1},
    TEST_WRAP_UP_PAGE: {url: AppRoutes.TEST_WRAP_UP_PAGE, name: 'Test Wrap Up', component: TestWrapUpPage, isNav: 0},
    TEST_LIST_PAGE: {url: AppRoutes.TEST_LIST_PAGE.replace(':id', getUserId()), name: 'Test List', component: TestListPage, isNav: 1},
    ADMIN_PAGE: {url: AppRoutes.ADMIN_PAGE, name: 'Admin', component: AdminPage, isNav: 1},
    ADMIN_TEST_LIST_PAGE: {url: AppRoutes.ADMIN_TEST_LIST_PAGE, name: 'Admin Test List', component: AdminTestListPage, isNav: 0},
    ADMIN_QUESTIONS_PAGE: {url: AppRoutes.ADMIN_QUESTIONS_PAGE, name: 'Admin Questions', component: AdminQuestionsPage, isNav: 0},
    TEST_TERMS_PAGE: {url: AppRoutes.TEST_TERMS_PAGE, name: 'Test Terms', component: TestTermsPage, isNav: 0},
    RESET_PASSWORD_REQUEST_PAGE: {url: AppRoutes.RESET_PASSWORD_REQUEST_PAGE, name: 'Reset Password', component: ResetPasswordRequestPage, isNav: 0 },
    RESET_PASSWORD_PAGE: {url: AppRoutes.RESET_PASSWORD_PAGE, name: 'Reset Password', component: ResetPasswordPage, isNav: 0 },
    GENERAL_ORDERS_STATS_PAGE: {url: AppRoutes.GENERAL_ORDERS_STATS_PAGE, name: 'General Orders Statistics', component: GeneralOrdersPage, isNav: 0},
    GEOLOCATION_USER_DETAIL: {url: AppRoutes.GEOLOCATION_USER_DETAIL, name: 'Geolocation User Detail', component: GeolocationUserDetail, isNav: 0},
    GEOLOCATION_USERS: {url: AppRoutes.GEOLOCATION_USERS, name: 'Geolocation Users', component: GeolocationUsers, isNav: 0}
}
