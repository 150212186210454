export const REGISTRATION_RESPONSE = 'REGISTRATION_RESPONSE';
export function registrationResponse(response) {
    return {
        type: REGISTRATION_RESPONSE,
        response: response
    }
}

export const FACEBOOK_BUTTON_CLICKED = 'FACEBOOK_BUTTON_CLICKED';
export function facebookButtonClicked() {
    return {
        type: FACEBOOK_BUTTON_CLICKED
    }
}

export const FACEBOOK_ERROR = 'FACEBOOK_ERROR';
export function facebookError() {
    return {
        type: FACEBOOK_ERROR
    }
}
