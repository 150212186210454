let ApiConfig = {
    // protocol: 'http://',
    protocol: 'https://',
    apiUri: 'api.bluelineadvances.com',
    // apiUri: 'generatedbla.local',
    socketProtocol: 'ws://',
    socketUri: 'api.bluelineadvances.com',
    socketPort: ':9090',
    uploadEndpoint: '/upload',
    facebookRegistrationEndpoint: '/user/facebook_register',
    registrationEndpoint: '/user/register',
    loginEndpoint: '/user/login',
    userList: '/user',

    testEndpoint: '/test/',
    generalOrdersStatisticsEndpoint: '/test/statistics',
    questionEndpoint: '/question/',
    testQuestionEndpoint: '/test_question/',
    authorizationEndpoint: '/authorization/',
    passwordResetRequestEndpoint: '/user/resetRequest',
    passwordResetEndpoint: '/user/reset',
    verifyResetHash: '/user/verifyResetHash',
    lastSyncs: '/question/getLastSyncs',
    geolocation: '/user/saveGeolocation',
    getGeolocationForUser: '/user/getGeolocationForUser/',
    adminQuestions: '/question/getQuestions'
};

export default ApiConfig;
