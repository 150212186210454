import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {TableRow, TableCell,} from "@material-ui/core";


class GeolocationUserDetailListItem extends Component {
  render() {
    return (
      <TableRow className="admin-row" hidden={this.props.hidden}>
        <TableCell>{this.props.data.lat}</TableCell>
        <TableCell>{this.props.data.lng}</TableCell>
        <TableCell>{this.props.data.accuracy}m</TableCell>
        <TableCell>{this.props.data.type}</TableCell>
      </TableRow>
    );
  }
}

GeolocationUserDetailListItem.propTypes = {
  data: PropTypes.object.isRequired
};

export default GeolocationUserDetailListItem;
