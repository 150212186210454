import React, {Component, PropTypes} from 'react';
import RefreshIndicator from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';

class CastleLoader extends Component {
    render() {
        let refreshStatus = this.props.waiting ? "loading" : "hide";
        return (
            <div className="mainLoader"
                 style={{
                     position: 'fixed',
                     top: 0,
                     left: 0,
                     width: '100%',
                     height: '100%',
                 }}
                 hidden={!this.props.waiting}
            >
                <RefreshIndicator
                  status={"loading"}
                  size={80}
                  left={-40}
                  top={-40}
                  style={{
                      marginLeft: '50%',
                      marginTop: '50vh',
                      backgroundColor: '#505050',
                  }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        waiting: state.ui.waiting
    }
};

var ConnectedCastleLoader = connect(mapStateToProps)(CastleLoader);

export default ConnectedCastleLoader;
