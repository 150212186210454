import React, {Component} from 'react';
import PropTypes from 'prop-types';
import fetchData from "../../util/forms/basicFetch";
import LoadAwareFormLayout from "../../util/forms/components/LoadAwareFormLayout";

class BaseDataContainer extends Component {
  componentWillMount() {
    fetchData(this, false);
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children,
      (child) => React.cloneElement(child, {
        data: this.props.data,
        fetchData
      })
    );

    const loadingState = this.props.loading ? "loading" : "hide";

    return <LoadAwareFormLayout
			id={this.props.id}
			wrapperClassName={this.props.className}
			childrenWithProps={childrenWithProps}
			loadingState={loadingState}
			loading={this.props.loading}
		/>;
  }
}

BaseDataContainer.propTypes = {
  endpoint: PropTypes.string.isRequired,
  autoLoad: PropTypes.bool,
  id: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default BaseDataContainer;
