import React, { PureComponent } from 'react';
import { TableContainer, Table } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

class GeneralOrdersView extends PureComponent {
  
  getCellForSectionAndDuration = (bin, duration) => {
    if(!this.props || !this.props.data) {
      return '-%';
    }
    
    const sectionList = this.props.data[duration];
    if(!sectionList || !sectionList.length) {
      return '-%';
    }
    
    if(bin === 'all') {
      console.log(duration);
      console.log('sectionList', sectionList);
      const totalCorrect = sectionList.reduce((accumulator, element, index) => {
        return accumulator + parseInt(element.total_correct)
      },
      0);
      const percentCorrect = totalCorrect / parseInt(sectionList[0].num_tests);
      return percentCorrect.toFixed(0) + '%';
    }
    const sectionObj = sectionList.find((element) => element.bin === bin);
    return (sectionObj && sectionObj.percent) ? Number.parseFloat(sectionObj.percent).toFixed(0) + '%' : '0%';
  };
  
  render() {
    return (
      <div className="general-orders-stats-table-wrapper">
        <TableContainer className="general-orders-stats-table-container">
          <Table>
            <TableHead>
              <TableRow className="general-orders-stats-header-row">
                <TableCell className="general-orders-stats-header-cell">
                
                </TableCell>
                <TableCell className="general-orders-stats-header-cell">
                  LAST TEST
                </TableCell>
                <TableCell className="general-orders-stats-header-cell">
                  7-DAY AVG.
                </TableCell>
                <TableCell className="general-orders-stats-header-cell">
                  30-DAY AVG.
                </TableCell>
                <TableCell className="general-orders-stats-header-cell">
                  ALL-TIME AVG.
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="general-orders-stats-body-row">
                <TableCell className="general-orders-stats-left-title-cell">
                  100
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('100', 'latest')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('100', 'lastSeven')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('100', 'lastThirty')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('100', 'allTime')}
                </TableCell>
              </TableRow>
              <TableRow className="general-orders-stats-body-row">
                <TableCell className="general-orders-stats-left-title-cell">
                  200
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('200', 'latest')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('200', 'lastSeven')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('200', 'lastThirty')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('200', 'allTime')}
                </TableCell>
              </TableRow>
              <TableRow className="general-orders-stats-body-row">
                <TableCell className="general-orders-stats-left-title-cell">
                  300
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('300', 'latest')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('300', 'lastSeven')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('300', 'lastThirty')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('300', 'allTime')}
                </TableCell>
              </TableRow>
              <TableRow className="general-orders-stats-body-row">
                <TableCell className="general-orders-stats-left-title-cell">
                  400
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('400', 'latest')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('400', 'lastSeven')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('400', 'lastThirty')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('400', 'allTime')}
                </TableCell>
              </TableRow>
              <TableRow className="general-orders-stats-body-row">
                <TableCell className="general-orders-stats-left-title-cell">
                  500
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('500', 'latest')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('500', 'lastSeven')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('500', 'lastThirty')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('500', 'allTime')}
                </TableCell>
              </TableRow>
              <TableRow className="general-orders-stats-body-row">
                <TableCell className="general-orders-stats-left-title-cell">
                  600
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('600', 'latest')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('600', 'lastSeven')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('600', 'lastThirty')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('600', 'allTime')}
                </TableCell>
              </TableRow>
              <TableRow className="general-orders-stats-body-row">
                <TableCell className="general-orders-stats-left-title-cell">
                  700
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('700', 'latest')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('700', 'lastSeven')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('700', 'lastThirty')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('700', 'allTime')}
                </TableCell>
              </TableRow>
              <TableRow className="general-orders-stats-body-row">
                <TableCell className="general-orders-stats-left-title-cell">
                  800
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('800', 'latest')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('800', 'lastSeven')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('800', 'lastThirty')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('800', 'allTime')}
                </TableCell>
              </TableRow>
              <TableRow className="general-orders-stats-body-row">
                <TableCell className="general-orders-stats-left-title-cell">
                  900
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('900', 'latest')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('900', 'lastSeven')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('900', 'lastThirty')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('900', 'allTime')}
                </TableCell>
              </TableRow>
              <TableRow className="general-orders-stats-body-row">
                <TableCell className="general-orders-stats-left-title-cell">
                  ALL
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('all', 'latest')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('all', 'lastSeven')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('all', 'lastThirty')}
                </TableCell>
                <TableCell className="general-orders-stats-body-cell">
                  {this.getCellForSectionAndDuration('all', 'allTime')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default GeneralOrdersView;
