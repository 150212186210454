import fetch from 'isomorphic-fetch';
import {schemaResponse, schemaRequestCompleted, autoloadingFormEdited, schemaAutoSuggestRequestCompleted, replaceCurrentUser} from '../schemaActions';
import {schemaError, unknownError, addError} from '../errorActions';
import ApiConfig from '../../config/ApiConfig';
import doNothing from './successHandlers/doNothing';
import standardResponseHandler from './standardResponseHandler';
import {createBrowserHistory} from 'history';
const browserHistory = createBrowserHistory();

export function doSchemaGet(id, accessToken, endpoint, cacheUser = false) {
	return function(dispatch) {
		
		return fetch(endpoint, {
			method: 'GET',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Authorization' : 'Bearer ' + accessToken,
				'Accept' : 'application/json',
				'Content-Type' : 'application/json'
			}
		})
		.then(response => {
			standardResponseHandler(response, dispatch, getError, getSuccessful, null, null, id, true, null, cacheUser);
		})
		.catch(error => {
			dispatch(schemaRequestCompleted(id));
			dispatch(schemaError(error))
		})
	}
}

export function doAutoSuggestGet(id, accessToken, endpoint, property) {
	return function(dispatch) {
		
		return fetch(endpoint, {
			method: 'GET',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Authorization' : 'Bearer ' + accessToken,
				'Accept' : 'application/json',
				'Content-Type' : 'application/json'
			}
		})
		.then(response => {
			standardResponseHandler(response, dispatch, getError, getAutoSuggestSuccessful, null, null, id, true, property);
		})
		.catch(error => {
			dispatch(schemaRequestCompleted(id));
			dispatch(schemaError(error))
		})
	}
}

function errorHandler(error, dispatch) {
	dispatch(schemaError(error))
}

function getError(error, dispatch, id) {
	dispatch(schemaRequestCompleted(id));
	dispatch(schemaError(error))
}

function getSuccessful(json, dispatch, id) {
	dispatch(schemaRequestCompleted(id));
	dispatch(schemaResponse(json, id));
}

function getAutoSuggestSuccessful(json, dispatch, id, property) {
	dispatch(schemaAutoSuggestRequestCompleted(json, id, property));
	dispatch(schemaRequestCompleted(id));
}

export function doListDelete(parentId, accessToken, endpoint, listEndpoint) {
	return function(dispatch) {
		return fetch(endpoint, {
			method: 'DELETE',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Authorization' : 'Bearer ' + accessToken,
				'Accept' : 'application/json',
				'Content-Type' : 'application/json'
			}
		})
		.then(response => {
			standardResponseHandler(response, dispatch, errorHandler,
				() => {
					reinitializeGet(parentId, accessToken, listEndpoint, dispatch)
				}, null, null, null, false);
		})
		.catch(error => {
			reinitializeGet(parentId, accessToken, endpoint, dispatch);
			dispatch(unknownError(error));
		})
	}
}

function reinitializeGet(id, accessToken, endpoint, dispatch) {
	dispatch(doSchemaGet(id, accessToken, endpoint));
}

export function doRedirectCreate(accessToken, endpoint, redirectUrl, payload, message = "") {
	return function(dispatch) {
		var bodyJson = JSON.stringify(payload);
		return fetch(endpoint, {
			method: 'POST',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Authorization' : 'Bearer ' + accessToken,
				'Accept' : 'application/json',
				'Content-Type' : 'application/json'
			},
			body: bodyJson
		})
		.then(response => {
			const castToJson = false;
			standardResponseHandler(response, dispatch, errorHandler, doNothing, message, redirectUrl, null, castToJson);
		})
		.catch(error => {
			dispatch(unknownError(error))
		})
	}
}

export function doPasswordResetRequest(endpoint, payload, callback = doNothing) {
  return function(dispatch) {
    var bodyJson = JSON.stringify(payload);
    return fetch(endpoint, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      },
      body: bodyJson
    })
      .then(response => {
        standardResponseHandler(response, dispatch, errorHandler, callback, null, null, null, true);
      })
      .catch(error => {
        dispatch(unknownError(error))
      })
  }
}

export function doSchemaCreate(accessToken, endpoint, castToJson, payload, callback = doNothing) {
	return function(dispatch) {
		var bodyJson = JSON.stringify(payload);
		return fetch(endpoint, {
			method: 'POST',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Authorization' : 'Bearer ' + accessToken,
				'Accept' : 'application/json',
				'Content-Type' : 'application/json'
			},
			body: bodyJson
		})
		.then(response => {
			standardResponseHandler(response, dispatch, errorHandler, callback, null, null, null, castToJson);
		})
		.catch(error => {
			dispatch(unknownError(error))
		})
	}
}

export function doRedirectFullUpdate(accessToken, endpoint, redirectUrl, payload, message = "", id = null) {
	return fullUpdate(accessToken, endpoint, payload, redirectUrl, message, id);
}

export function doRedirectPartialUpdate(accessToken, endpoint, redirectUrl, payload, message = null, id = null, callback = doNothing) {
	return partialUpdate(accessToken, endpoint, payload, redirectUrl, message, id, false, callback);
}

export function doCustomFullUpdate(accessToken, endpoint, payload, callback, message = "", id = null, castToJson = true) {
	return fullUpdate(accessToken, endpoint, payload, null, message, id, castToJson, callback);
}

function partialUpdate(accessToken, endpoint, payload, redirectUrl = null, message = null, id = null, castToJson = false, callback = doNothing) {
	console.log(callback);
  return function(dispatch) {
    var bodyJson = JSON.stringify(payload);
    return fetch(endpoint, {
      method: 'PATCH',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Authorization' : 'Bearer ' + accessToken,
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      },
      body: bodyJson
    })
      .then(response => {
        standardResponseHandler(response, dispatch, errorHandler, callback, message, redirectUrl, id, castToJson);
      })
      .catch(error => {
        dispatch(unknownError(error))
      })
  }
}

function fullUpdate(accessToken, endpoint, payload, redirectUrl = null, message = "", id = null, castToJson = false, callback = doNothing) {
	return function(dispatch) {
		var bodyJson = JSON.stringify(payload);
		return fetch(endpoint, {
			method: 'PUT',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Authorization' : 'Bearer ' + accessToken,
				'Accept' : 'application/json',
				'Content-Type' : 'application/json'
			},
			body: bodyJson
		})
		.then(response => {
			standardResponseHandler(response, dispatch, errorHandler, callback, message, redirectUrl, id, castToJson);
		})
		.catch(error => {
			dispatch(unknownError(error))
		})
	}
}

export function doRedirectDelete(accessToken, endpoint, redirectUrl, message = "") {
	return function(dispatch) {
		return fetch(endpoint, {
			method: 'DELETE',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Authorization' : 'Bearer ' + accessToken,
				'Accept' : 'application/json',
				'Content-Type' : 'application/json'
			}
		})
		.then(response => {
			const castToJson = false;
			standardResponseHandler(response, dispatch, errorHandler, doNothing, message, redirectUrl, null, castToJson);
		})
		.catch(error => {
			dispatch(unknownError(error))
		})
	}
}

export function doPutSchemaUpdate(payload, accessToken, endpoint, id, successRedirectUrl = null, displaySuccessMessage = true) {
	return function(dispatch) {
		var bodyJson = JSON.stringify(payload);
		let successMessage = displaySuccessMessage ? "Updated Successfully" : null;
		
		return fetch(endpoint, {
			method: 'PUT',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Authorization' : 'Bearer ' + accessToken,
				'Accept' : 'application/json',
				'Content-Type' : 'application/json'
			},
			body: bodyJson
		})
		.then(response => {
			standardResponseHandler(response, dispatch, errorHandler, updateSuccessful,
				successMessage, successRedirectUrl, id);
		})
		.catch(error => {
			console.error(error);
			dispatch(addError("Update failed"))
		})
	}
}

export function doSchemaUpdate(payload, accessToken, endpoint, id) {
	return function(dispatch) {
		var bodyJson = JSON.stringify(payload);
		
		return fetch(endpoint, {
			method: 'PATCH',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Authorization' : 'Bearer ' + accessToken,
				'Accept' : 'application/json',
				'Content-Type' : 'application/json'
			},
			body: bodyJson
		})
		.then(response => {
			standardResponseHandler(response, dispatch, errorHandler, updateSuccessful, "Updated Successfully", null, id);
		})
		.catch(error => {
			console.error(error);
			dispatch(addError("Update failed"))
		})
	}
}


function updateOwnUser(json, dispatch, redirectUrl) {
	if(json.id) {
		dispatch(replaceCurrentUser(json));
		browserHistory.push(redirectUrl)
	}
	else {
		dispatch(addError("Something went wrong"));
	}
}

export function doRedirectUpdateOwnUser(payload, accessToken, endpoint, redirectUrl) {
	return function(dispatch) {
		var bodyJson = JSON.stringify(payload);
		
		return fetch(endpoint, {
			method: 'POST',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Authorization' : 'Bearer ' + accessToken,
				'Accept' : 'application/json',
				'Content-Type' : 'application/json'
			},
			body: bodyJson
		})
		.then(response => {
			standardResponseHandler(response, dispatch, errorHandler,
				(json, dispatch, id, property) => updateOwnUser(json, dispatch, redirectUrl));
		})
		.catch(error => {
			console.error(error);
			dispatch(addError("Update failed"))
		})
	}
}

export function doFileUpload(file, accessToken, endpoint, id, property) {
	return function(dispatch) {
		let data = new FormData();
		data.append('file', file);
		return fetch(endpoint, {
			method: 'POST',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Authorization' : 'Bearer ' + accessToken,
				'Accept' : 'application/json',
			},
			body: data
		})
		.then(response => {
			standardResponseHandler(response, dispatch, errorHandler, handleImageReplacement,
				null, null, id, true, property);
		})
		.catch(error => {
			console.error(error);
			dispatch(addError("Unable to upload file"))
		})
	}
}

function updateSuccessful(json, dispatch, id) {
	dispatch(schemaResponse(json, id));
}

function handleImageReplacement(json, dispatch, id, property) {
	let uri = json.uri;
	let url = ApiConfig.protocol + ApiConfig.apiUri + json.uri;
	dispatch(autoloadingFormEdited(id, property, url));
}
