import React, {Component} from 'react';
import AppRoutes from "../../config/AppRoutes";
import {getCurrentUser, getUserId} from '../../util/storage/accessTokenStorage';
import BlaButton from "../../ui/buttons/BlaButton";

class TestWrapUpView extends Component {
  reviewTest = () => {
    this.props.history.push(AppRoutes.TEST_REVIEW_PAGE.replace(':id', this.props.testId).replace(':index', '0'));
  };

  newTest = () => {
    let authorizationId = getCurrentUser().user.authorizationId;
    this.props.history.push(AppRoutes.TEST_SELECTION_PAGE.replace(':id', authorizationId));
  }

  myTests = () => {
    this.props.history.push(AppRoutes.TEST_LIST_PAGE.replace(':id', getUserId()))
  }

  get totalScore() {
    if(!this.props.data.length) {
      return '';
    }
    let correct = 0;
    let total = 0;
    for(const bin of this.props.data) {
      let tempCorrect = bin.correct === null ? 0 : bin.correct;
      correct += parseInt(tempCorrect);
      total += parseInt(bin.total);
    }

    const number = Math.floor(correct/total * 100);
    return number.toString() + '%';
  }

  get binScores() {
    if(!this.props.data.length)
      return '';
    return this.props.data.map(binScore => {
      return (
        <div className="bin-row" key={binScore.bin}>
          <div className="bin-label">
            {binScore.bin}
          </div>
          <div className="bin-percent">
            {this.getBinPercent(binScore)}
          </div>
        </div>
      );
    });
  }

  getBinPercent(bin) {
    const correct = bin.correct;
    const total = bin.total;
    const number = Math.floor(correct/total * 100);
    return number.toString() + '%';
  }

  render() {
    if(!this.props.data) {
      return "Loading...";
    }
    return(
      <div className="test-wrap-up-wrapper">
        <div className="test-wrap-up-top-score">
          TOTAL: {this.totalScore}
        </div>

        <div className="test-bin-scores">
          {this.binScores}
        </div>
        <div className="flex-container answer-action-container">
          <BlaButton disabled={false} onClick={this.reviewTest} label="Review Test" />
          <BlaButton disabled={false} onClick={this.newTest} label="New Test" />
          <BlaButton disabled={false} onClick={this.myTests} label="My Tests" />
        </div>
      </div>
    )
  }
}

export default TestWrapUpView;
