import React, {Component, PropTypes} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {errorCleared} from '../actions/uiActions';
import {connect} from 'react-redux';

class CastleError extends Component {

    errorClosed = () => {
        this.props.dispatch(errorCleared());
    };

    render() {
        return (
            <div className="message-container">
                <Snackbar
                    open={this.props.errorReceived}
                    message={this.props.errorMessage}
                    autoHideDuration={4000}
                    onClose={this.errorClosed}
                    style={{
                        backgroundColor: 'red',
                        color: 'black'
                    }}
                    className="errorSnackbar"
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        errorReceived: state.ui.errorReceived,
        errorMessage: state.ui.errorMessage,
    }
};

var ConnectedCastleError = connect(mapStateToProps)(CastleError);

export default ConnectedCastleError;
