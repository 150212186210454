import React, {Component} from 'react';
import CenteredPaperLayout from '../../layouts/CenteredPaperLayout';
import TestListListItem from '../../pages/partials/list_items/TestListListItem';
import AppRoutes from '../../config/AppRoutes';
import ApiConfig from '../../config/ApiConfig';
import NoData from '../partials/empty_data/NoData';
import NonAdminListDataContainer from "../../containers/NonAdminListDataContainer";
import BlaButton from '../../ui/buttons/BlaButton';

class TestListPage extends Component {
    render() {
        let endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.testEndpoint;
        return (
            <CenteredPaperLayout>
              <h2 className={"bla-centered-paper-layout"}>TESTS</h2>
              <BlaButton label="General Orders Statistics Page" onClick={() => this.props.history.push(AppRoutes.GENERAL_ORDERS_STATS_PAGE)}/>
                <NonAdminListDataContainer
                    id="testListPage"
                    endpoint={endpoint}
                    sortControls={true}
                    createControl={true}
                    search={true}
                    noData={NoData}
                    itemComponent={TestListListItem}
                    createUrl={AppRoutes.TEST_LIST_PAGE}
                    history={this.props.history}
                />
            </CenteredPaperLayout>
        )
    }
}

export default TestListPage;
