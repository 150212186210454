export default {
    ui: {
        waiting: false,
        errorReceived: false,
        messageReceived: false,
        errorMessage: "",
        message: ""
    },
    autoLoaders: {
        loadingList: {},
        loaders: {}
    },
    currentUser: {
        user: null,
        accessToken: null,
        expiry: null
    }
}