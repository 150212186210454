import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {TableRow, TableCell,} from "@material-ui/core";
import AppRoutes from '../../../config/AppRoutes';
import {createBrowserHistory} from 'history';
const browserHistory = createBrowserHistory();
var parser = require('ua-parser-js');


class AdminListItem extends Component {

    onEditEntity = (event, id) => {
        if(this.props.onEditEntity) {
            this.props.onEditEntity(event, id);
            return;
        }

        event.preventDefault();
        browserHistory.push("/app/test/edit/" + id);
    };

    render() {
      const userAgent = this.props.data.userAgent ? this.props.data.userAgent : null;
      let uAstring = 'Unknown Device';
      if(userAgent) {
        const parsedUserAgent = parser(userAgent);

        const browser = parsedUserAgent.browser;
        const os = parsedUserAgent.os;

        const browserNameString = browser.name ? browser.name : 'unknown browser name';
        const browserVersionString = browser.version ? browser.version : 'unknown version';

        const osNameString = os.name ? os.name : 'unknown OS name';
        const osVersionString = os.version ? os.version : 'unknown OS version';

        uAstring = browserNameString + ' ' + browserVersionString + ' / ' + osNameString + ' ' + osVersionString;
      }

      const testLink = AppRoutes.TEST_REVIEW_PAGE.replace(':id', this.props.data.id).replace(':index', '0');

        return (
          <TableRow className="admin-row" hidden={this.props.hidden}>
              <TableCell>{this.props.data.email}</TableCell>
              <TableCell>{this.props.data.score}</TableCell>
              <TableCell>{this.props.data.createdOn}</TableCell>
              <TableCell>{this.props.data.ipAddress}</TableCell>
              <TableCell>{this.props.data.isComplete === '1' ? 'Yes' : 'No'}</TableCell>
              <TableCell>
                {uAstring}
                <div className="admin-link"><Link to={testLink}>test</Link></div>
              </TableCell>
          </TableRow>
        );
    }
}

AdminListItem.propTypes = {
    data: PropTypes.object.isRequired,
    isDividerHidden: PropTypes.bool,
    onEditEntity: PropTypes.func
};

export default AdminListItem;
