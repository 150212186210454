import React, {Component} from 'react';
import CenteredPaperLayout from '../../layouts/CenteredPaperLayout';
import DataContainer from '../../containers/DataContainer';
import ApiConfig from '../../config/ApiConfig';
import TestView from "../views/TestView";


class TestReviewPage extends Component {
    render() {
      let endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.testEndpoint + this.props.match.params.id;

        return (
          <DataContainer
              id="testReviewPage"
              endpoint={endpoint}
          >
              <TestView history={this.props.history} questionIndex={this.props.match.params.index} reviewMode />
          </DataContainer>
        )
    }
}

export default TestReviewPage;
