import fetch from 'isomorphic-fetch';
import ApiConfig from '../../config/ApiConfig';
import {loginResponse} from '../loginActions';
import {schemaError} from '../errorActions';
import {accessTokenAcquired} from '../globalActions';
import {addMessage, unWait, wait} from '../uiActions';
import AppRoutes from '../../config/AppRoutes';
import redirect from '../redirect/redirect';
import standardResponseHandler from './standardResponseHandler';

export function syncQuestions(accessToken) {
  return function(dispatch) {
    let {protocol, apiUri, questionEndpoint} = ApiConfig;
    dispatch(wait());

    let url = protocol + apiUri + questionEndpoint + "syncQuestions";
    let body = {};
    let bodyJson = JSON.stringify(body);

    return fetch(url, {
      body: bodyJson,
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization' : 'Bearer ' + accessToken,
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      }
    })
      .then(response => standardResponseHandler(response, dispatch, errorHandler, handleSuccessfulQuestionSync))
      .catch(error => dispatch(schemaError(error)))
  }
}

export function doSyncGoQuestions(accessToken, callback) {
  return syncQuestionsOfType(accessToken, 'syncGeneralOrdersQuestions', callback);
}

export function doSyncErpQuestions(accessToken, callback) {
  return syncQuestionsOfType(accessToken, 'syncEmergencyResponsePlanQuestions', callback);
}

export function doSyncCommanderQuestions(accessToken, callback) {
  return syncQuestionsOfType(accessToken, 'syncCommanderQuestions', callback);
}

export function doSyncSergeant2024Questions(accessToken, callback) {
  return syncQuestionsOfType(accessToken, 'syncSergeant2024Questions', callback);
}

export function doSyncSergeantCombined2020Questions(accessToken, callback) {
  return syncQuestionsOfType(accessToken, 'syncSergeantCombined2020Questions', callback);
}

export function doSyncCamQuestions(accessToken, callback) {
  return syncQuestionsOfType(accessToken, 'syncCorrectiveActionQuestions', callback);
}

export function doSyncGoCaptainQuestions(accessToken, callback) {
  return syncQuestionsOfType(accessToken, 'syncGeneralOrdersCaptainQuestions', callback);
}

export function doSyncGoSergeantQuestions(accessToken, callback) {
  return syncQuestionsOfType(accessToken, 'syncGeneralOrdersSergeantQuestions', callback)
}

export function syncQuestionsOfType(accessToken, endpoint, callback) {
  return function(dispatch) {
    let {protocol, apiUri, questionEndpoint} = ApiConfig;
    dispatch(wait());

    let url = protocol + apiUri + questionEndpoint + endpoint;
    let body = {};
    let bodyJson = JSON.stringify(body);

    return fetch(url, {
      body: bodyJson,
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization' : 'Bearer ' + accessToken,
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      }
    })
      .then(response => standardResponseHandler(response, dispatch, errorHandler, callback))
      .catch(error => dispatch(schemaError(error)))
  }
}

export function syncAuthorization(accessToken, callback) {
  return function(dispatch) {
    let {protocol, apiUri, authorizationEndpoint} = ApiConfig;
    dispatch(wait());

    let url = protocol + apiUri + authorizationEndpoint + "syncAuthorization";
    let body = {};
    let bodyJson = JSON.stringify(body);

    return fetch(url, {
      body: bodyJson,
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization' : 'Bearer ' + accessToken,
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      }
    })
      .then(response => standardResponseHandler(response, dispatch, errorHandler, callback))
      .catch(error => dispatch(schemaError(error)))
  }
}

function errorHandler(error, dispatch) {
  dispatch(schemaError(error))
}

function handleSuccessfulQuestionSync(json, dispatch) {
  dispatch(unWait);
  if(!json.error) {
    dispatch(addMessage("Questions Synced!"));
  }
  else { //Needed for OAuth Server's error handling :(
    if(json.error_description) {
      let error = {message: json.error_description};
      dispatch(schemaError(error));
    }
    else {
      dispatch(schemaError(json));
    }
  }
}

function handleSuccessfulAuthorizationSync(json, dispatch) {
  dispatch(unWait);
  if(!json.error) {
    dispatch(addMessage("Authorization Synced!"));
  }
  else { //Needed for OAuth Server's error handling :(
    if(json.error_description) {
      let error = {message: json.error_description};
      dispatch(schemaError(error));
    }
    else {
      dispatch(schemaError(json));
    }
  }
}
