import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BaseDataContainer from './BaseDataContainer'
import FontIcon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import RefreshIndicator from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHeader from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableHeaderColumn from '@material-ui/core/TableCell';
import mapFetchToProps from '../../util/mapFetchToProps';
import { connect } from 'react-redux';

class BaseGeolocationUserListContainer extends BaseDataContainer {
  constructor() {
    super();
    this.state = {
      filterText: "",
      alphaSort: null,
      timeSort: "dsc"
    }
  }
  
  getDate = (rawTime) => {
    const t = rawTime.split(/[- :]/);
    return new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
  };
  
  onChange = (event) => {
    const value = event.target.value;
    this.setState({
      filterText: value
    });
  };
  
  getLabel = (schemaItem) => {
    return schemaItem.email;
  };
  
  createElementsFromListItems = (itemData, index) => {
    if(itemData.hidden) {
      return null;
    }
    const listItemElement = React.createElement(
      this.props.itemComponent,
      itemData
    );
    return (
      listItemElement
    );
  };
  
  getListItemDataFromSchemaItem = (schemaItem, index) => {
    
    let label = this.getLabel(schemaItem);
    let filterText = this.state.filterText.toLowerCase();
    let hidden = !label.toLowerCase().includes(filterText);
    
    return {key: schemaItem.id, data: schemaItem, label, avatar: null, hidden};
  };
  
  getSearchMarkup = () => {
    return <div className="list-filter">
      <FontIcon className="material-icons">search</FontIcon>
      <input placeholder="search" className="list-filter-input" type="text" value={this.state.filterText} onChange={this.onChange} />
    </div>;
  };
  
  
  render() {
    const loadingState = this.props.loading ? "loading" : "hide";
    
    let list = React.createElement(this.props.noData);
    
    if(this.props.data && this.props.data.length) {
      let listItemData = this.props.data.map(this.getListItemDataFromSchemaItem);
      list = listItemData.map(this.createElementsFromListItems);
    }
    
    
    const searchMarkup = this.getSearchMarkup();
    
    return (
      <div id={this.props.id} className="schema-list-item-wrapper">
        {searchMarkup}
        <Table>
          <TableHeader>
            <TableRow className="admin-list-header">
              <TableHeaderColumn><h3 className={"bla-centered-paper-layoutTwo"}>EMAIL</h3></TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {list}
          </TableBody>
        </Table>
        {
          this.props.loading
          && (
            <RefreshIndicator
              status={loadingState}
              hidden={!this.props.loading}
              left={-20}
              top={0}
              className="local-spinner"
            />
          )
        }
      </div>
    )
  }
}

BaseGeolocationUserListContainer.propTypes = {
  id: PropTypes.string.isRequired,
  accessToken: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  itemComponent: PropTypes.func.isRequired,
  noData: PropTypes.func.isRequired,
};

export default connect(mapFetchToProps)(BaseGeolocationUserListContainer);
