import {REGISTRATION_RESPONSE} from '../actions/registrationActions';
import {ACCESS_TOKEN_ACQUIRED, LOG_OUT_USER} from '../actions/globalActions';
import getCurrentTimestamp from '../util/getCurrentTimestamp';
import {LOGIN_RESPONSE} from '../actions/loginActions';
import {REPLACE_CURRENT_USER} from '../actions/schemaActions';
import initialState from '../state/initialState';
//import {REHYDRATE} from 'redux-persist/constants' //TODO: fix rehydrate reducer (look into persistReducer(config, reducer))

function registrationSuccessfulCurrentUserReduction(state, action) {
	let response = action.response;
	let accessToken = response.access_token;
	let expiresIn = response.expires_in;
	let user = response.user;
	let timeStamp = getCurrentTimestamp();
	const expiry = timeStamp + expiresIn;
	
	return Object.assign({}, state, {user: user, accessToken: accessToken, expiry: expiry});
}

function replaceCurrentUser(state, action) {
	let user = action.user;
	
	return Object.assign({}, state, {user: user});
}

function logOutUserReduction() {
	return initialState.currentUser;
}
export default function currentUser(state = {}, action) {
	switch(action.type) {
		case REGISTRATION_RESPONSE:
		case LOGIN_RESPONSE:
			return registrationSuccessfulCurrentUserReduction(state, action);
		case ACCESS_TOKEN_ACQUIRED:
			return state;
		case LOG_OUT_USER:
			return state;
			return logOutUserReduction();
		case REPLACE_CURRENT_USER:
			return replaceCurrentUser(state, action);
		case 'REHYDRATE':
			if(state.accessToken) {
				return state;
			}
			return (action && action.payload && action.payload.currentUser) ?
				action.payload.currentUser : state;
		default:
			return state;
	}
}
