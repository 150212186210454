import React, {Component} from 'react';
import BigLayout from "../../layouts/BigLayout";
import ApiConfig from '../../config/ApiConfig';
import NoData from '../partials/empty_data/NoData';
import AdminListItem from '../../pages/partials/list_items/AdminListItem';
import AppRoutes from '../../config/AppRoutes';
import ListDataContainer from '../../containers/ListDataContainer';
import AdminNavigation from '../partials/navigation/AdminNavigation';
import {getCurrentUser} from '../../util/storage/accessTokenStorage';
import {connect} from 'react-redux';
import {logOutUser} from '../../actions/globalActions';
import AdminQuestionListDataContainer from '../../containers/AdminQuestionListDataContainer';
import BaseDataContainer from '../../containers/base/BaseDataContainer';
import DataContainer from '../../containers/DataContainer';

class AdminQuestionsPage extends Component {
  getPage = () => {
    switch(this.props.match.params.type) {
      case 'GO':
        return 'General Orders';
      case 'GOC':
        return 'General Orders Captain';
      case 'ERP':
        return 'Emergency Response Plan';
      case 'CAM':
        return 'Corrective Actions Manual';
      case 'GOS':
        return 'General Orders Sergeant';
      default:
        return '';
    }
  }

  getHeader = () => {
    switch(this.props.match.params.type) {
      case 'GO':
        return 'General Orders Questions';
      case 'GOC':
        return 'General Orders Captain Questions';
      case 'ERP':
        return 'Emergency Response Plan Questions';
      case 'CAM':
        return 'Corrective Actions Manual Questions';
      case 'GOS':
        return 'General Orders Sergeant Questions';
      default:
        return '';
    }
  }

  validateAdminUser = (user) => {
    if(user.user.isAdmin !== '1') {
      this.props.dispatch(logOutUser(this.props.history));
    }
  };

  render() {
    const user = getCurrentUser();
    this.validateAdminUser(user);

    const endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.adminQuestions + '/' + this.props.match.params.type;

    return (
      <div>
        <AdminNavigation history={this.props.history} page={this.getPage()} />
        <BigLayout>
          <h2 className={"bla-centered-paper-layout"}>{this.getHeader()}</h2>

          {this.props.match.params.type === 'GO' &&
          <DataContainer endpoint={endpoint} id="GOAdminQuestions">
            <AdminQuestionListDataContainer

            />
          </DataContainer>
          }

          {this.props.match.params.type === 'GOC' &&
          <DataContainer endpoint={endpoint} id="GOCAdminQuestions">
            <AdminQuestionListDataContainer

            />
          </DataContainer>
          }

          {this.props.match.params.type === 'GOS' &&
          <DataContainer endpoint={endpoint} id="GOSAdminQuestions">
            <AdminQuestionListDataContainer

            />
          </DataContainer>
          }

          {this.props.match.params.type === 'ERP' &&
          <DataContainer endpoint={endpoint} id="ERPAdminQuestions">
            <AdminQuestionListDataContainer

            />
          </DataContainer>
          }

          {this.props.match.params.type === 'CAM' &&
          <DataContainer endpoint={endpoint} id="CAMAdminQuestions">
            <AdminQuestionListDataContainer

            />
          </DataContainer>
          }
        </BigLayout>
      </div>
    );
  }
}

AdminQuestionsPage.propTypes = {};

export default connect()(AdminQuestionsPage);
