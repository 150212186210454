import React, {Component} from 'react';
import {
    Step,
    Stepper,
    StepLabel,
} from '@material-ui/core';

class HorizontalTwoStep extends Component {
    render() {
        return (
          <Stepper className="stepper" activeStep={this.props.stepIndex} style={{padding: '0 24px'}}>
            <Step className="step">
                <StepLabel className="first_label">{this.props.firstLabel}</StepLabel>
            </Step>
            <Step className="step">
                <StepLabel className="second_label">{this.props.secondLabel}</StepLabel>
            </Step>
          </Stepper>
        );
    }
}

export default HorizontalTwoStep;
