import React, {Component} from 'react';
import CenteredPaperLayout from '../../layouts/CenteredPaperLayout';
import DataContainer from '../../containers/DataContainer';
import TestSelectionView from '../views/TestSelectionView';
import ApiConfig from '../../config/ApiConfig';


class TestSelectionPage extends Component {
    render() {
        let endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.authorizationEndpoint + this.props.match.params.id;

        return (
            <CenteredPaperLayout>
                <DataContainer
                    id="testSelectionPage"
                    endpoint={endpoint}
                >
                    <TestSelectionView history={this.props.history} />
                </DataContainer>
            </CenteredPaperLayout>
        )
    }
}

export default TestSelectionPage;
