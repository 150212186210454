import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BaseDataContainer from './BaseDataContainer'
import RefreshIndicator from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHeader from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableHeaderColumn from '@material-ui/core/TableCell';
import mapFetchToProps from '../../util/mapFetchToProps';
import { connect } from 'react-redux';

class BaseGeolocationUserListContainer extends BaseDataContainer {
  getLabel = (schemaItem) => {
    return schemaItem.email;
  };
  
  createElementsFromListItems = (itemData, index) => {
    if(itemData.hidden) {
      return null;
    }
    const listItemElement = React.createElement(
      this.props.itemComponent,
      itemData
    );
    return (
      listItemElement
    );
  };
  
  getListItemDataFromSchemaItem = (schemaItem, index) => {
    let label = this.getLabel(schemaItem);
    return {key: schemaItem.id, data: schemaItem, label, avatar: null, hidden: false};
  };
  
  
  render() {
    const loadingState = this.props.loading ? "loading" : "hide";
    
    let list = React.createElement(this.props.noData);
    
    if(this.props.data && this.props.data.length) {
      let listItemData = this.props.data.map(this.getListItemDataFromSchemaItem);
      list = listItemData.map(this.createElementsFromListItems);
    }
    
    return (
      <div id={this.props.id} className="schema-list-item-wrapper">
        <h2>GEOLOCATION FOR USER: {this.props.data && this.props.data.length && this.props.data[0].user.email}</h2>
        <Table>
          <TableHeader>
            <TableRow className="admin-list-header">
              <TableHeaderColumn><h3 className={"bla-centered-paper-layoutTwo"}>LATITUDE</h3></TableHeaderColumn>
              <TableHeaderColumn><h3 className={"bla-centered-paper-layoutTwo"}>LONGITUDE</h3></TableHeaderColumn>
              <TableHeaderColumn><h3 className={"bla-centered-paper-layoutTwo"}>ACCURACY</h3></TableHeaderColumn>
              <TableHeaderColumn><h3 className={"bla-centered-paper-layoutTwo"}>TYPE</h3></TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {list}
          </TableBody>
        </Table>
        {
          this.props.loading
          && (
            <RefreshIndicator
              status={loadingState}
              hidden={!this.props.loading}
              left={-20}
              top={0}
              className="local-spinner"
            />
          )
        }
      </div>
    )
  }
}

BaseGeolocationUserListContainer.propTypes = {
  id: PropTypes.string.isRequired,
  accessToken: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  itemComponent: PropTypes.func.isRequired,
  noData: PropTypes.func.isRequired,
};

export default connect(mapFetchToProps)(BaseGeolocationUserListContainer);
