import React, {Component} from 'react';
import BlaButton from '../../ui/buttons/BlaButton';

class SyncButtonsView extends Component {
  render() {
    return (
      <div className="flex-container sync-button-main-wrapper">
        {/*<div className="sync-button-wrapper">*/}
        {/*  <BlaButton disabled={false} onClick={this.props.doSyncGoSergeantQuestions} label="Sync G.O." />*/}
        {/*  <div className="sync-button-last-sync">Last Sync: {this.props.data.last_gos_sync}</div>*/}
        {/*</div>*/}
        <div className="sync-button-wrapper">
          <BlaButton disabled={false} onClick={this.props.syncGoQuestions} label="Sync G.O." />
          <div className="sync-button-last-sync">Last Sync: {this.props.data.last_go_sync}</div>
        </div>
        {/*<div className="sync-button-wrapper">*/}
        {/*  <BlaButton disabled={false} onClick={this.props.doSyncGoCaptainQuestions} label="Sync G.O. CMDR" />*/}
        {/*  <div className="sync-button-last-sync">Last Sync: {this.props.data.last_goc_sync}</div>*/}
        {/*</div>*/}
        <div className="sync-button-wrapper">
          <BlaButton disabled={false} onClick={this.props.syncCamQuestions} label="Sync CAM" />
          <div className="sync-button-last-sync">Last Sync: {this.props.data.last_cam_sync}</div>
        </div>
        <div className="sync-button-wrapper">
          <BlaButton disabled={false} onClick={this.props.syncErpQuestions} label="Sync ERP" />
          <div className="sync-button-last-sync">Last Sync: {this.props.data.last_erp_sync}</div>
        </div>
        {/*<div className="sync-button-wrapper">*/}
        {/*  <BlaButton disabled={false} onClick={this.props.syncCommander} label="Sync Commander" />*/}
        {/*  <div className="sync-button-last-sync">Last Sync: {this.props.data.last_cm_sync}</div>*/}
        {/*</div>*/}
        <div className="sync-button-wrapper">
          <BlaButton disabled={false} onClick={this.props.doSyncSergeant2024Questions} label="Sync Sergeant 2024 Questions" />
          <div className="sync-button-last-sync">Last Sync: {this.props.data.last_s2_sync}</div>
        </div>
        {/*<div className="sync-button-wrapper">*/}
        {/*  <BlaButton disabled={false} onClick={this.props.doSyncSergeantCombined2020Questions} label="Sync Sergeant Combined 2020 Questions" />*/}
        {/*  <div className="sync-button-last-sync">Last Sync: {this.props.data.last_sc2_sync}</div>*/}
        {/*</div>*/}
        <div className="sync-button-wrapper">
          <BlaButton disabled={false} onClick={this.props.doSyncAuthorization} label="Sync Authorization" />
          <div className="sync-button-last-sync">Last Sync: {this.props.data.last_ua_sync}</div>
        </div>
      </div>
    );
  }
}

SyncButtonsView.propTypes = {};

export default SyncButtonsView;
