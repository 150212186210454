import React, { PureComponent } from 'react';
import ApiConfig from '../../config/ApiConfig';
import BlaButton from '../../ui/buttons/BlaButton';
import AppRoutes from '../../config/AppRoutes';
import CenteredPaperLayout from '../../layouts/CenteredPaperLayout';
import DataContainer from '../../containers/DataContainer';
import GeneralOrdersView from '../views/GeneralOrdersView';

class GeneralOrdersPage extends PureComponent {
  render() {
    let endpoint = ApiConfig.protocol + ApiConfig.apiUri + ApiConfig.generalOrdersStatisticsEndpoint;
    
    
    return (
      <CenteredPaperLayout className="general-orders-stats-layout">
        <h2 className={"bla-centered-paper-layout"}>GENERAL ORDERS STATS</h2>
        <BlaButton label="BACK" onClick={() => this.props.history.push(AppRoutes.TEST_LIST_PAGE)}/>
        <DataContainer
          id="generalOrdersStats"
          endpoint={endpoint}
        >
          <GeneralOrdersView />
        </DataContainer>
      </CenteredPaperLayout>
    )
  }
}

export default GeneralOrdersPage;
