import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import FlatButton from '@material-ui/core/Button';

class RegistrationOptions extends Component {

    render() {
        return (
            <div className="registration-options-wrapper">
                <p>Don't have an account?
                    <br></br>
                    <Link className={"bla-hype-style"} to="/register">Register</Link>
                </p>
            </div>
        );
    }
}

export default RegistrationOptions;
