import React, {Component} from 'react';
import Formsy from 'formsy-react';
import {connect} from 'react-redux';
import FormsyText from '../../../components/formsy/FormsyText';
import {doLogin} from '../../../actions/thunks/loginThunks';
import BlaButton from "../../../ui/buttons/BlaButton";
import { addError } from '../../../actions/errorActions';
import {wait} from '../../../actions/uiActions';

class LoginInputs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {
                email: "",
                password: ""
            },
            disabled: true
        };
        document.onkeydown = function (e) {
          e = e || document.event;
          switch (e.which || e.keyCode) {
            case 13 :
              this.handleLogin();
              break;
          }
        }.bind(this)
    }

    componentDidMount(){
        //this.nameInput.focus(); //TODO: Fix?
    }

    handleValid = () => {
        this.setState({
            disabled: false
        });
    };

    handleInvalid = () => {
        this.setState({
            disabled: true
        });
    };

    handleEmailChange = (event) => {
        const newEmail = event.target.value;
        let user = Object.assign({}, this.state.user);
        user.email = newEmail;

        this.setState({
            user
        })
    };

    handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        let user = Object.assign({}, this.state.user);
        user.password = newPassword;

        this.setState({
            user
        })
    };

    handleLogin = () => {
        this.props.dispatch(wait());
        if(!navigator || !navigator.geolocation) {
            this.props.dispatch(addError('Your browser does not support geolocation. Please use a browser that supports geolocation'));
            return;
        }
        
        navigator.geolocation.getCurrentPosition(
          (position) => {
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
              const accuracy = position.coords.accuracy;
              let user = Object.assign({}, this.state.user);
              this.props.dispatch(doLogin(user, this.props.history, { lat, lng, accuracy }));
          },
          (error) => {
              this.props.dispatch(addError('Unable to determine geolocation. You must provide geolocation to use this service.'));
        });
    };
    
    

    render() {
        let passwordMessage = "How are you gonna login without a password?";
        let emailMessage = "Real emails only";

        return (
            <div className="bla-login-wrapper">
                <Formsy
                  onValid={this.handleValid}
                  onInvalid={this.handleInvalid}
                >
                    <div className="bla-login-email-wrapper">
                        <h3 className={"bla-centered-paper-layoutTwo"}>LOG IN</h3>
                        <FormsyText
                            name="email"
                            label="Email"
                            required
                            validations="isEmail"
                            validationError={emailMessage}
                            value={this.state.user.email}
                            onChange={this.handleEmailChange}
                        />
                    </div>
                    <div className="bla-login-password-wrapper">
                        <FormsyText
                          name="password"
                          label="Password"
                          type="password"
                          required
                          validationError={passwordMessage}
                          onChange={this.handlePasswordChange}
                          value={this.state.user.password}
                        />
                    </div>
                    <div className="castle-login-button">
                        <BlaButton label="Sign In" onClick={this.handleLogin} disabled={this.state.disabled} />
                    </div>
                </Formsy>
            </div>
        );
    }
}

const ConnectedLoginInputs = connect()(LoginInputs);

export default ConnectedLoginInputs;
