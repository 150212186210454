import {getAccessToken} from "../storage/accessTokenStorage";
import {doRedirectFullUpdate} from "../../actions/thunks/schemaThunks";
import {schemaRequest} from "../../actions/schemaActions";

const redirectUpdate = (component, payload = null, message = null, requestEndpoint = null, redirectUrl = null) => {
  console.log(redirectUrl);
  component.props.dispatch(schemaRequest(component.props.id));
  const data = payload || component.props.data;
  const successMessage = message || "Updated Successfully!";
  const endpoint = requestEndpoint || component.props.requestEndpoint;
  const url = redirectUrl || component.props.deleteRedirectUrl;
  const accessToken = component.props.accessToken || getAccessToken();

  component.props.dispatch(
    doRedirectFullUpdate(accessToken, endpoint, url, data, successMessage, component.props.id)
  );
};

export default redirectUpdate;