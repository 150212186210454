import React, {Component} from 'react';

class VerticallyCenter extends Component {

	render() {
		let containerStyle = Object.assign({}, {position: 'relative', height: this.props.height}, this.props.style);
		return(
			<div style={containerStyle}>
				<div style={{
					position: 'absolute',
					top: '50%',
					transform: 'translate(0,-50%)',
					WebkitTransform: 'translate(0,-50%)',
					MsTransform: 'translate(0,-50%)'
				}}>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default VerticallyCenter;
