export const ERROR_CLEARED = 'ERROR_CLEARED';
export function errorCleared() {
    return {
        type: ERROR_CLEARED
    }
}

export const MESSAGE_CLEARED = 'MESSAGE_CLEARED';
export function messageCleared() {
    return {
        type: MESSAGE_CLEARED
    }
}

export const ADD_MESSAGE = 'ADD_MESSAGE';
export function addMessage(message) {
    return {
        type: ADD_MESSAGE,
        message: message
    }
}

export const WAIT = 'WAIT';
export function wait() {
    return {
        type: WAIT
    }
}

export const UNWAIT = 'UNWAIT';
export function unWait() {
    return {
        type: UNWAIT
    }
}