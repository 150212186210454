import BaseEditContainer from './base/BaseEditContainer';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import mapFetchToProps from '../util/mapFetchToProps';
import {connect} from 'react-redux';

let EditDataContainer = connect(mapFetchToProps)(BaseEditContainer);

EditDataContainer.propTypes = {
	id: PropTypes.string.isRequired,
	uploadEndpoint: PropTypes.string,
	endpoint: PropTypes.string,
	requestEndpoint: PropTypes.string,
	avatarUpdateLabel: PropTypes.string,
	emptyMessage: PropTypes.string,
	accessToken: PropTypes.string,
	successMessage: PropTypes.string,
	redirectUrl: PropTypes.string,
	initialData: PropTypes.any,
	autoSuggestEndpoints: PropTypes.object,
};

export default EditDataContainer;
