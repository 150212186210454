import React, {Component} from 'react';
import {logOutUser} from '../../actions/globalActions';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';

class LogOutNavItem extends Component {

    logOut = () => {
        this.props.dispatch(logOutUser(this.props.history));
        this.props.closeDrawer();
    };

    render() {
        return(<Link onClick={this.logOut}>
            <MenuItem >
                <div className="nav-logout">
                    Log Out
                </div>
            </MenuItem>
        </Link>);
    }
}

let ConnectedLogOutNavItem = connect()(LogOutNavItem);
export default ConnectedLogOutNavItem;
