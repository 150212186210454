import React, {Component} from 'react';
import appSchemeMap from '../../util/appSchemeMap';
import FontIcon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationItem from '@material-ui/core/BottomNavigationAction';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu';
import MobileNavItem from './MobileNavItem';
import LogOutNavItem from './LogOutNavItem';
import {getCurrentUser} from '../../util/storage/accessTokenStorage';

const testIcon = <FontIcon className="material-icons">border_color</FontIcon>;
const listIcon = <FontIcon className="material-icons">list</FontIcon>;
const menuIcon = <FontIcon className="material-icons">menu</FontIcon>;
const nearbyIcon = <FontIcon className="material-icons">location_on</FontIcon>;
const errorIcon = <FontIcon className="material-icons">error</FontIcon>;

class MobileNav extends Component {

    constructor(props) {
        super(props);
        let selectedIndex = 3;
        let counter = 0;

        let firstNavItem = this.getNthNavItem(0);
        let secondNavItem = this.getNthNavItem(1);
        let thirdNavItem = this.getNthNavItem(2);
        let fourthNavItem = this.getNthNavItem(3);
        let fifthNavItem = this.getNthNavItem(4);

        if(firstNavItem && props.location.replace(":id", "") === firstNavItem.url.replace(":id", "")) {
            selectedIndex = 0;
        }
        else if(secondNavItem && props.location.replace(":id", "") === secondNavItem.url.replace(":id", "")) {
            selectedIndex = 1;
        }
        else if(thirdNavItem && props.location.replace(":id", "") === thirdNavItem.url.replace(":id", "")) {
            selectedIndex = 2;
        }
        else if(!fifthNavItem && fourthNavItem && props.location.replace(":id", "") === fourthNavItem.url.replace(":id", "")) {
            selectedIndex = 3;
        }

        this.state = {
            selectedIndex: selectedIndex,
            location: props.location,
            open: false,
            anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
            },
            targetOrigin: {
                horizontal: 'right',
                vertical: 'bottom',
            },
        };
    }

    iconNavSelected = (index) => {
        let firstNavItem = this.getNthNavItem(0);
        let secondNavItem = this.getNthNavItem(1);
        let thirdNavItem = this.getNthNavItem(2);
        let fourthNavItem = this.getNthNavItem(3);
        let fifthNavItem = this.getNthNavItem(4);

        this.setState({
            selectedIndex: index
        });
        switch(index){
            case 0:
                this.props.history.push(firstNavItem.url);
                break;
            case 1:
                this.props.history.push(secondNavItem.url);
                break;
            case 2:
                this.props.history.push(thirdNavItem.url);
                break;
            case 3:
                if(!fifthNavItem) {
                    this.props.history.push(fourthNavItem.url);
                }
        }
    };

    getNthNavItem = (n) => {
        let scheme = {};
        let counter = 0;
        const user = (getCurrentUser() && getCurrentUser().user) ? getCurrentUser().user : null;
        for(let i in appSchemeMap) {
            if(appSchemeMap.hasOwnProperty(i)) {
                scheme = appSchemeMap[i];
                if(scheme.isNav && (scheme.name !== 'Admin' || (user && user.isAdmin === "1"))) {
                    if(counter === n) {
                        return scheme;
                    }
                    counter++;
                }
            }
        }
        return null;
    };

    menuSelected = (event) => {
        event.preventDefault();
        this.setState({
            open: true,
            anchorEl: event.currentTarget,
            selectedIndex: 3
        });
    };

    menuClosed = () => {
        this.setState({
            open: false
        })
    };

    menuItemSelected = (newLocation, event) => {
        event.preventDefault();
        this.setState({
            open: false,
            location: newLocation,
            selectedIndex: 3
        });
        this.props.history.push(newLocation);
    };

    getLastItem = () => {
        let fifthNavItem = this.getNthNavItem(4);
        if(fifthNavItem) {
            return (
                <BottomNavigationItem
                    className={this.state.selectedIndex === 3 ? "selected-nav-item" : ""}
                    label="Menu"
                    icon={menuIcon}
                    onClick={this.menuSelected}
                />
            );
        }
        else {
            let fourthNavItem = this.getNthNavItem(3);
            if(!fourthNavItem) {
                return null;
            }
            return (
                <BottomNavigationItem
                    className={this.state.selectedIndex === 3 ? "selected-nav-item" : ""}
                    label={fourthNavItem.name}
                    icon={errorIcon}
                    onClick={() => this.iconNavSelected(3)}
                />
            )
        }
    };

    getLeftOverMobileNavItems = () => {
        let scheme = {};
        let counter = 0;
        let mobileNavItems = [];
        for(let i in appSchemeMap) {
            if(appSchemeMap.hasOwnProperty(i)) {
                scheme = appSchemeMap[i];
                if(scheme.isNav) {
                    if(counter > 2) {
                        mobileNavItems.push(
                            <MobileNavItem name={scheme.name} key={scheme.url} to={scheme.url} location={this.state.location}
                                           onClick={this.menuItemSelected}/>)
                    }
                    counter++;
                }
            }
        }
        return mobileNavItems;
    };

    getPopOver = () => {
        let fifthNavItem = this.getNthNavItem(4);
        if(fifthNavItem) {
            let leftOverMobileNavItems = this.getLeftOverMobileNavItems();
            return(
                <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={this.state.anchorOrigin}
                    targetOrigin={this.state.targetOrigin}
                    onRequestClose={this.menuClosed}
                >
                    <Menu>
                        {leftOverMobileNavItems}
                        <LogOutNavItem />
                    </Menu>
                </Popover>
            );
        }
    };

    getBottomNavigationItems = (firstNavItem, secondNavItem, thirdNavItem, lastItem) => {
        let navItems = [];
        if(firstNavItem) {
            navItems.push(
                <BottomNavigationItem
                    className={this.state.selectedIndex === 0 ? "selected-nav-item" : ""}
                    label={firstNavItem.name}
                    icon={testIcon}
                    onClick={() => this.iconNavSelected(0)}
                    key="1"
                />
            );
        }
        if(secondNavItem) {
            navItems.push(
                <BottomNavigationItem
                    className={this.state.selectedIndex === 1 ? "selected-nav-item" : ""}
                    label={secondNavItem.name}
                    icon={listIcon}
                    onClick={() => this.iconNavSelected(1)}
                    key="2"
                />
            );
        }
        if(thirdNavItem) {
            navItems.push(
                <BottomNavigationItem
                    className={this.state.selectedIndex === 2 ? "selected-nav-item" : ""}
                    label={thirdNavItem.name}
                    icon={nearbyIcon}
                    onClick={() => this.iconNavSelected(2)}
                    key="3"
                />
            );
        }

        if(lastItem) {
            navItems.push(lastItem);
        }

        return navItems;
    };

    render() {

        let lastItem = this.getLastItem();
        let popOver = this.getPopOver();
        let firstNavItem = this.getNthNavItem(0);
        let secondNavItem = this.getNthNavItem(1);
        let thirdNavItem = this.getNthNavItem(2);

        let bottomNavigationItems = this.getBottomNavigationItems(firstNavItem, secondNavItem, thirdNavItem, lastItem);

        return (
            <Paper zDepth={1}>
                <BottomNavigation className="castle-bottom-nav" selectedIndex={this.state.selectedIndex}>
                    {bottomNavigationItems}
                </BottomNavigation>
                {popOver}
            </Paper>
        );
    }
}

export default MobileNav;
