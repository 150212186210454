import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import BaseListContainer from './base/BaseListContainer';
import mapFetchToProps from '../util/mapFetchToProps';

let ListDataContainer = connect(mapFetchToProps)(BaseListContainer);

ListDataContainer.propTypes = { //for IDE. Delete if you want
	id: PropTypes.string.isRequired,
	accessToken: PropTypes.string,
	endpoint: PropTypes.string.isRequired,
	itemComponent: PropTypes.func.isRequired,
	noData: PropTypes.func.isRequired,
	sortControls: PropTypes.bool.isRequired,
	search: PropTypes.bool.isRequired,
	createUrl: PropTypes.string,
	getAvatar: PropTypes.func,
	getLabel: PropTypes.func,
	getIconButton: PropTypes.func
};

export default ListDataContainer;
