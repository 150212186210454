import React, {Component, PropTypes} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {messageCleared} from '../actions/uiActions';
import {connect} from 'react-redux';

class CastleMessage extends Component {

    errorClosed = () => {
        this.props.dispatch(messageCleared());
    };

    render() {
        return (
            <div className="message-container">
                <Snackbar
                    open={this.props.messageReceived}
                    message={this.props.message}
                    autoHideDuration={4000}
                    onClose={this.errorClosed}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        messageReceived: state.ui.messageReceived,
        message: state.ui.message,
    }
};

var ConnectedCastleMessage = connect(mapStateToProps)(CastleMessage);

export default ConnectedCastleMessage;
