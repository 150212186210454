import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BaseDataContainer from './BaseDataContainer'
import FontIcon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import RefreshIndicator from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHeader from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableHeaderColumn from '@material-ui/core/TableCell';

class BaseListContainer extends BaseDataContainer {
  constructor() {
    super();
    this.state = {
      filterText: "",
      alphaSort: null,
      timeSort: "dsc"
    }
  }

  getDate = (rawTime) => {
    const t = rawTime.split(/[- :]/);
    return new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
  };

  onTimeSort = () => {
    this.setState({
      timeSort: this.state.timeSort === "dsc" ? "asc" : "dsc",
      alphaSort: null
      
    })
  };

  onAlphabetSort = () => {
    this.setState({
      timeSort: null,
      alphaSort: this.state.alphaSort === "dsc" ? "asc": "dsc"
      
    });
  };

  onChange = (event) => {
    const value = event.target.value;
    this.setState({
      filterText: value
    });
  };

  getLabel = (schemaItem) => {
    return schemaItem.email;
  };

  doAscAlphabeticalSort = (item1, item2) => {
    let lower1 = item1.label.toLowerCase();
    let lower2 = item2.label.toLowerCase();
  
    if (lower1 < lower2) {
      return 1;
    }
    return -1;
  };
  
  doDscAlphabeticalSort = (item1, item2) => {
    let lower1 = item1.label.toLowerCase();
    let lower2 = item2.label.toLowerCase();
  
    if (lower1 < lower2) {
      return -1;
    }
    return 1;
  };
  
  doAscDateSort = (item1, item2) => {
    let date1 = this.getDate(item1.data.createdOn);
    let date2 = this.getDate(item2.data.createdOn);
  
    if (date1 < date2) {
      return -1;
    }
    return 1;
  };

  doDscDateSort = (item1, item2) => {
    let date1 = this.getDate(item1.data.createdOn);
    let date2 = this.getDate(item2.data.createdOn);
    
    if (date1 < date2) {
      return 1;
    }
    return -1;
  };
  
  sort = (item1, item2) => {
    if(this.state.alphaSort === "asc") {
      return this.doAscAlphabeticalSort(item1, item2);
    }
    else if (this.state.alphaSort === "dsc") {
      return this.doDscAlphabeticalSort (item1, item2);
      }
    else if (this.state.timeSort === "asc") {
      return this.doAscDateSort (item1, item2);
      }
    else if (this.state.timeSort === "dsc") {
      return this.doDscDateSort(item1, item2);
      }
  };

  createElementsFromListItems = (itemData, index) => {
    if(itemData.hidden) {
      return null;
    }
    const listItemElement = React.createElement(
      this.props.itemComponent,
      itemData
    );
    return (
      listItemElement
    );
  };

  getListItemDataFromSchemaItem = (schemaItem, index) => {

    let label = this.getLabel(schemaItem);
    let filterText = this.state.filterText.toLowerCase();
    let hidden = !label.toLowerCase().includes(filterText);

    return {key: schemaItem.id, data: schemaItem, label, avatar: null, hidden};
  };

  getSearchMarkup = () => {
    return <div className="list-filter">
			<FontIcon className="material-icons">search</FontIcon>
			<input placeholder="search" className="list-filter-input" type="text" value={this.state.filterText} onChange={this.onChange} />
		</div>;
  };

  getSortControlMarkup = (alphaSort) => {
    let alphaControl = this.getAlphaControl(alphaSort);
    let dateControl = this.getDateControl(this.state.timeSort);

    return <div className="list-header">
      {alphaControl}
      {dateControl}
		</div>;
  };

  getAlphaControl = (alphaSort) => {
    let alphabetClassName = !alphaSort ? "selected-sort-icon" : "";
    let topArrowClassName = alphaSort === "asc" ? "material-icons-custom-arrows faint" : "material-icons-custom-arrows faint hidden";
    let bottomArrowClassName = alphaSort === "dsc" ? "material-icons-custom-down-arrows faint" : "material-icons-custom-arrows faint hidden";
    
    return <IconButton className={alphabetClassName} onClick={this.onAlphabetSort} style={{position: 'absolute', right: 70}}>
      <FontIcon className={topArrowClassName} >keyboard_arrow_up</FontIcon>
      <FontIcon className="material-icons-custom faint" >text_format</FontIcon>
      <FontIcon className={bottomArrowClassName} >keyboard_arrow_down</FontIcon>
		</IconButton>;

  };

  getDateControl = (timeSort) => {
    let timeClassName = !timeSort ? "selected-sort-icon" : "";
    let topArrowClassName = timeSort === "asc" ? "material-icons-custom-arrows faint" : "material-icons-custom-arrows faint hidden";
    let bottomArrowClassName = timeSort === "dsc" ? "material-icons-custom-down-arrows faint" : "material-icons-custom-arrows faint hidden";

    return <IconButton className={timeClassName} onClick={this.onTimeSort} style={{position: 'absolute', right: 22}}>
      <FontIcon className={topArrowClassName} >keyboard_arrow_up</FontIcon>
      <FontIcon className="material-icons-custom faint" >access_time</FontIcon>
      <FontIcon className={bottomArrowClassName} >keyboard_arrow_down</FontIcon>
		</IconButton>;
  };

 


  render() {
    const loadingState = this.props.loading ? "loading" : "hide";

    let list = React.createElement(this.props.noData);

    if(this.props.data && this.props.data.length) {
      let listItemData = this.props.data.map(this.getListItemDataFromSchemaItem);
      listItemData.sort(this.sort);
      list = listItemData.map(this.createElementsFromListItems);
    }

    let searchMarkup = null;
    if(this.props.search) {
      searchMarkup = this.getSearchMarkup();
    }
    
    let controlMarkup = this.getSortControlMarkup(this.state.alphaSort);

    const childrenWithProps = React.Children.map(this.props.children,
      (child) => React.cloneElement(child, {
        data: this.props.data
      })
    );
    return (
			<div id={this.props.id} className="schema-list-item-wrapper">
        {searchMarkup}
        {controlMarkup}
        <Table>
          <TableHeader>
            <TableRow className="admin-list-header">
              <TableHeaderColumn><h3 className={"bla-centered-paper-layoutTwo"}>USER</h3></TableHeaderColumn>
              <TableHeaderColumn><h3 className={"bla-centered-paper-layoutTwo"}>SCORES</h3></TableHeaderColumn>
              <TableHeaderColumn><h3 className={"bla-centered-paper-layoutTwo"}>DATE</h3></TableHeaderColumn>
              <TableHeaderColumn><h3 className={"bla-centered-paper-layoutTwo"}>IP ADDRESS</h3></TableHeaderColumn>
              <TableHeaderColumn><h3 className={"bla-centered-paper-layoutTwo"}>COMPLETE</h3></TableHeaderColumn>
              <TableHeaderColumn><h3 className={"bla-centered-paper-layoutTwo"}>USER AGENT</h3></TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
          {list}
          </TableBody>
        </Table>
        
        <div className={"scrollable-data-list"}>
          {childrenWithProps}
        </div>
        {
          this.props.loading
          && (
            <RefreshIndicator
              status={loadingState}
              hidden={!this.props.loading}
              left={-20}
              top={0}
              className="local-spinner"
            />
          )
        }
			</div>
    )
  }
}

BaseListContainer.propTypes = {
  id: PropTypes.string.isRequired,
  accessToken: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  itemComponent: PropTypes.func.isRequired,
  noData: PropTypes.func.isRequired,
  sortControls: PropTypes.bool.isRequired,
  search: PropTypes.bool.isRequired,
  createControl: PropTypes.bool,
  createUrl: PropTypes.string,
  getAvatar: PropTypes.func,
  getLabel: PropTypes.func,
  getIconButton: PropTypes.func,
  mobileCreateLabel: PropTypes.string,
  desktopCreateLabel: PropTypes.string
};

export default BaseListContainer;
