import React from 'react';
import RaisedButton from '@material-ui/core/Button';

const BlaButton = (props) => {
  const additionalClass = props.emphasized ? 'emphasized' : '';
  return (
    <div>
      {/*<RaisedButton
        variant="contained"
        disableElevation
        className={ additionalClass + " " + "internal-bla-button"}
        labelColor="#fff"
        labelStyle={{fontSize: "10px", fontFamily: "Catamaran", letterSpacing: "2px"}}
        label={props.label}
        onTouchTap={props.onTouchTap}
        disabled={props.disabled}
        onClick={props.onClick}
      />*/}
  
      <RaisedButton
        variant="contained"
        disableElevation={props.disabled}
        disabled={props.disabled}
        onClick={props.onClick}
        classes={{
          root: additionalClass + " " + "internal-bla-button"
        }}
      >
        {props.label}
      </RaisedButton>

    </div>
  );
};

export default BlaButton;
